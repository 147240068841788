import {
	animate,
	state,
	style,
	transition,
	trigger,
} from "@angular/animations";

const ANIMATION_TIMINGS = "800ms cubic-bezier(0.25, 0.8, 0.25, 1)";

export const ANIMATIONS = [
	trigger("expandAnimation", [
		state("void", style({ opacity: 0, height: "0" })),
		transition("void <=> *", animate(ANIMATION_TIMINGS)),
	]),

	trigger("fadeInOut", [
		transition(":enter", [
			style({ opacity: 0 }), // Initial state when entering
			animate(ANIMATION_TIMINGS, style({ opacity: 1 })), // Final state
		]),
		transition(":leave", [
			style({ opacity: 1 }), // Initial state when leaving
			animate(ANIMATION_TIMINGS, style({ opacity: 0 })), // Final state
		]),
	]),

	trigger("fadeRight", [
		transition(":enter", [
			style({ transform: "translateX(-100%)", opacity: 0 }),
			animate(ANIMATION_TIMINGS, style({ transform: "translateX(0)", opacity: 1 })),
		]),
		transition(":leave", [
			style({ transform: "translateX(0)", opacity: 1 }),
			animate(ANIMATION_TIMINGS, style({ transform: "translateX(100%)", opacity: 0 })),
		]),
	]),

	trigger("fadeLeft", [
		transition(":enter", [
			style({ transform: "translateX(100%)", opacity: 0 }),
			animate(ANIMATION_TIMINGS, style({ transform: "translateX(0)", opacity: 1 })),
		]),
		transition(":leave", [
			style({ transform: "translateX(0)", opacity: 1 }),
			animate(ANIMATION_TIMINGS, style({ transform: "translateX(-100%)", opacity: 0 })),
		]),
	]),

	trigger("fadeUp", [
		transition(":enter", [
			style({ transform: "translateY(100%)", opacity: 0 }), // Start from below and transparent
			animate(ANIMATION_TIMINGS, style({ transform: "translateY(0)", opacity: 1 })), // Move to center and visible
		]),
		transition(":leave", [
			style({ transform: "translateY(0)", opacity: 1 }), // Start from center and visible
			animate(ANIMATION_TIMINGS, style({ transform: "translateY(-100%)", opacity: 0 })), // Move above and fade out
		]),
	]),

	trigger("fadeDown", [
		transition(":enter", [
			style({ transform: "translateY(-100%)", opacity: 0 }), // Start from above and transparent
			animate(ANIMATION_TIMINGS, style({ transform: "translateY(0)", opacity: 1 })), // Move to center and visible
		]),
		transition(":leave", [
			style({ transform: "translateY(0)", opacity: 1 }), // Start from center and visible
			animate(ANIMATION_TIMINGS, style({ transform: "translateY(100%)", opacity: 0 })), // Move below and fade out
		]),
	]),

];
