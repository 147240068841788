import { Pipe, PipeTransform } from "@angular/core";
import { IPagination } from "@sportyano/core/models/response/response.interface";

@Pipe({
	name: "localPagination",
	standalone: true,
})
export class LocalPaginationPipe implements PipeTransform {
	transform<T>(value: Array<T>, paginationConfig: IPagination): Array<T> {
		if (!value || value.length === 0) {
			return [];
		}

		const start =
			(paginationConfig.current_page - 1) * paginationConfig.per_page;
		const end = start + paginationConfig.per_page;

		return value.slice(start, end);
	}
}
