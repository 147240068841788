import { CommonModule, isPlatformBrowser } from "@angular/common";
import {
	Component,
	OnDestroy,
	OnInit,
	PLATFORM_ID,
	WritableSignal,
	inject,
	signal,
} from "@angular/core";
import { Router } from "@angular/router";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import {
	IMediaVideo,
} from "@sportyano/account/components/news-and-media/models/interfaces/video.interface";
import { Videos } from "@sportyano/core/models/account/news";
import { FILE_TYPE } from "@sportyano/core/models/file-upload/file-upload.model";
import { IPagination } from "@sportyano/core/models/response/response.interface";
import { AuthService } from "@sportyano/core/services/authServices/auth.service";
import { MediaService } from "@sportyano/core/services/media/media.service";
import { NewsService } from "@sportyano/core/services/playerProfile/news.service";
import { ToasterMessagesService } from "@sportyano/core/services/toaster-messages/toaster-messages.service";
import { AddPostPopUpComponent, CartVideoComponent, PaginationComponent } from "@sportyano/shared/components";
import { LocalPaginationPipe } from "@sportyano/shared/pipes/local-pagination/local-pagination.pipe";
import { ConfirmationService } from "primeng/api";
import { ButtonModule } from "primeng/button";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { TabViewModule } from "primeng/tabview";
import { Subject, takeUntil } from "rxjs";

@Component({
	selector: "tab-videos",
	templateUrl: "./videos.component.html",
	styleUrl: "./videos.component.scss",
	standalone: true,
	imports: [
		CommonModule,
		AddPostPopUpComponent,
		TabViewModule,
		ConfirmDialogModule,
		ButtonModule,
		TranslateModule,
		CartVideoComponent,
		PaginationComponent,
		LocalPaginationPipe
	],
})
export class VideosComponent implements OnInit , OnDestroy {
	isAddingVideo: boolean = false;
	isEditingVideo: boolean = false;
	playerId: number = 0;
	activeIndex: number = 0;
	approvedVideosTitleTab: any;
	declinePendingVideosTitleTab: any;
	// public _selectedVideo: any;
	public _selectedVideo: WritableSignal<any> = signal(null);

	public visibleEditVideo: boolean = false;
	constructor(
		private authService: AuthService,
		private newsServices: NewsService
	) {
		this.playerId = this.authService.getUserData()?.id;
		this.selectedItem.playerId = +this.playerId;
	}
	private _router = inject(Router);
	private platform = inject(PLATFORM_ID);
	private _mediaService = inject(MediaService);
	private _confirmationService = inject(ConfirmationService);
	private _translateService = inject(TranslateService);
	private _toaster = inject(ToasterMessagesService);
	// Signals
	public listOfVideos: WritableSignal<IMediaVideo[]> = signal([]);
	public listOfVideosPending: WritableSignal<IMediaVideo[]> = signal([]);
	public numberOfUploadedVideo: WritableSignal<number> = signal(0);
	// Public
	public $destroy: Subject<boolean> = new Subject<boolean>();
	public fileType = FILE_TYPE.video;
	public maximumUploadedVideo: number = 4;
	userHasPermission: boolean = true;
	pageIndex: number = 1;
	pageSize: number = 10;
	public paginationConfig: IPagination = {
		current_page: 1,
		per_page: 5,
		total_pages: 0,
		total_records: 0,
	};
	playesdArray = [
		{
			id: 0,
			isSelected: false,
		},
		{
			id: 1,
			isSelected: false,
		},
		{
			id: 2,
			isSelected: false,
		},
		{
			id: 3,
			isSelected: false,
		},
		{
			id: 0,
			isSelected: false,
		},
		{
			id: 4,
			isSelected: false,
		},
		{
			id: 5,
			isSelected: false,
		},
		{
			id: 6,
			isSelected: false,
		},
		{
			id: 7,
			isSelected: false,
		},
		{
			id: 8,
			isSelected: false,
		},
	];
	willAddItem: boolean = false;
	showToaster: boolean = false;
	message: string = "Post Added SUCCESSFULLY";
	selectedItem: Videos = {} as Videos;

	ngOnInit(): void {
		this.getAllVideos();
		this.getAllVideosPending();
		this._router.events.pipe(takeUntil(this.$destroy)).subscribe({
			next: () => {
				if (isPlatformBrowser(this.platform)) {
					const currentUrl = this._router.url;
					if (currentUrl.includes("account")) {
						this.userHasPermission = true;
					} else {
						this.userHasPermission = false;
					}
				}
			},
		});
		this.approvedVideosTitleTab = this._translateService.instant(
			"videos.Approve_Videos"
		);
		this.declinePendingVideosTitleTab = this._translateService.instant(
			"videos.pending_or_decline_videos"
		);
	}

	addNewPost() {
		this.willAddItem = true;
		this.visibleEditVideo = false;
	}

	public onVideoUpload(): void {
		this.showToaster = true;
		this.willAddItem = false;
		this.getAllVideosPending();
		// Increment Uploaded Videos by One
		this.numberOfUploadedVideo.update((n) => n + 1);
		setTimeout(() => {
			this.showToaster = false;
		}, 3000);
	}

	deleteItems() {
		this.playesdArray = this.playesdArray.filter(
			(x) => x.isSelected === false
		);
	}
	anyItemSelected(): boolean {
		return this.playesdArray.some((item) => item.isSelected);
	}

	changePage(e: any) {
		this.pageIndex = e.pageIndex;
		this.pageSize = e.pageSize;
	}

	getAllVideos() {
		if (this.playerId) {
			this.newsServices.getVideos("players", this.playerId).subscribe({
				next: (res) => {
					this.listOfVideos.set(res.videos);
					console.log(this.listOfVideos(), "published");

					this.paginationConfig.total_records =
						this.listOfVideos()?.length;
					this.numberOfUploadedVideo.set(
						this.listOfVideos()?.length + (res?.pending_count || 0)
					);
					// console.log(this.listOfVideos());
				},
			});
		}
	}
	getAllVideosPending() {
		if (this.playerId) {
			this.newsServices.getVideosPending().subscribe({
				next: (res) => {
					this.listOfVideosPending.set(res?.data!);
					console.log(this.listOfVideosPending(), "pending");

					this.paginationConfig.total_records =
						this.listOfVideosPending()?.length;
					this.numberOfUploadedVideo.set(
						this.listOfVideosPending()?.length +
							(res?.pending_count || 0)
					);
					// console.log(this.listOfVideosPending());
				},
			});
		}
	}

	public deleteUserVideo(videoId: number) {
		this._confirmationService.confirm({
			header: this._translateService.instant("confirmation.areYouSure"),
			message: this._translateService.instant(
				"account.account_management.news_Media.deleteVideo"
			),
			icon: "pi pi-exclamation-triangle",
			accept: () =>
				this._mediaService
					.deleteVideo(this.playerId, videoId)
					.subscribe({
						next: () => {
							this._deleteVideoById(videoId);
							this.getAllVideosPending();
							this.numberOfUploadedVideo.update((n) => n - 1);
						},
					}),
			reject: () => {},
		});
	}
	willEditItem(item: Videos) {
		console.log(" Called willEditItem");
		// this.willAddItem = true;
		this.selectedItem = { ...item };
		console.log("this.selectedItem", this.selectedItem);
	}
	selectedItemPending: any;
	willEditItemPending(item: Videos) {
		console.log(" Called willEditItemPending");
		this.selectedItemPending = { ...item };
		this.willAddItem = false;
	}
	public deleteUserVideoPending(videoId: number) {
		this._confirmationService.confirm({
			header: this._translateService.instant("confirmation.areYouSure"),
			message: this._translateService.instant(
				"account.account_management.news_Media.deleteVideo"
			),
			icon: "pi pi-exclamation-triangle",
			accept: () =>
				this._mediaService.deleteVideoPending(videoId).subscribe({
					next: () => {
						this._toaster.showSuccess(
							this._translateService.instant("videos.deleted")
						);
						this.getAllVideosPending();
					},
				}),
			reject: () => {},
		});
	}

	public _deleteVideoById(videoId: number) {
		this.listOfVideos.update((u) => {
			return u.filter((video) => video.id !== videoId);
		});
	}

	// public getVideosByPlayerId(playerId: number) {
	// 	this.newsServices
	// 		.getVideos("players" || "academy" || "playground", playerId)
	// 		.subscribe({
	// 			next: (res) => {
	// 				this.listOfVideos.set(res.videos);
	// 			},
	// 		});
	// }

	public onEditVideo(data: any) {
		this._selectedVideo.set(data);
		this.visibleEditVideo = true;
		console.log(this._selectedVideo());
	}

	public fn(event: unknown) {
		if (this._selectedVideo()["status"] === 1) {
			this._mediaService
				.updateVideoTitleAndDescription(
					this.playerId,
					this._selectedVideo().id,
					event
				)
				.subscribe({
					next: () => {
						this._toaster.showSuccess(
							this._translateService.instant(
								"videos_images_news_Messages.video_pending"
							)
						);
						this.getAllVideos();
						this.getAllVideosPending();
						this.numberOfUploadedVideo.update((n) => n - 1);
						// this._deleteVideoById(this._selectedVideo().id);
						this.visibleEditVideo = false;
					},
				});
		} else if (this._selectedVideo()["status"] === 2) {
			console.log(this._selectedVideo(), "from parent component");

			this._mediaService
				.updateVideoTitleAndDescriptionPending(
					// this.playerId,
					this._selectedVideo().id,
					this._selectedVideo()
				)
				.subscribe({
					next: () => {
						this._toaster.showSuccess(
							this._translateService.instant(
								"videos_images_news_Messages.video_pending"
							)
						);
						// this._deleteVideoById(this._selectedVideo().id);
						this.getAllVideosPending();
						this.getAllVideos();
						this.numberOfUploadedVideo.update((n) => n - 1);
						this.visibleEditVideo = false;
					},
				});
		}
	}
	ngOnDestroy(): void {
		this.$destroy.next(true);
		this.$destroy.complete();
	}
}
