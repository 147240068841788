import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { StaticWebsiteLoginComponent } from "@sportyano/auth/static-website-login/static-website-login.component";
import { staticWebsiteGuardGuard } from "@sportyano/core/guards/static-website-guard.guard";

export const routes: Routes = [
	{
		path: "main",
		canActivate: [staticWebsiteGuardGuard],
		loadChildren: () =>
			import("@sportyano/main-site/main-site-routes").then(
				(m) => m.routesMain
			),
	},
	{
		path: "auth",
		canActivate: [staticWebsiteGuardGuard],
		loadChildren: () =>
			import("@sportyano/auth/auth-routes").then((m) => m.routesAuth),
	},
	{
		path: "",
		redirectTo: "main",
		pathMatch: "full",
	},
	{
		path: "guard",
		component: StaticWebsiteLoginComponent,
	},
	{
		path: "unauthorized",

		loadComponent: () =>
			import("@sportyano/core/unauthorized/unauthorized.component").then(
				(c) => c.UnauthorizedComponent
			),
	},
	{
		path: "**",

		loadComponent: () =>
			import(
				"@sportyano/core/page-not-found/page-not-found.component"
			).then((c) => c.PageNotFoundComponent),
	},
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			scrollPositionRestoration: "top",
			enableViewTransitions: true,
		}),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}
