import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SinglePlayerService {
	private _http = inject(HttpClient);

  constructor() { }
  

  singlePlayer$ = new BehaviorSubject<any>({})
  
  //get Advertisement 
  public getAdvertisement(page:string,device:string){
		return this._http.get<any>(`${environment.baseUrl}home-page/advertisment?page=${page}&device=${device}`)
	
  }
}
