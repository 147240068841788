import { CommonModule } from '@angular/common';
import { Component, inject, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-cart-review',
	templateUrl: './cart-review.component.html',
	styleUrl: './cart-review.component.scss',
	standalone: true,
    imports: [CommonModule]
})
export class CartReviewComponent {
	@Input() cardData: any
	@Input() cardType: 'playground' | 'academy';
	descriptionToShow:string; 
	readMoreText:string;
	//INJECT
	_translateService=inject(TranslateService);
	constructor() { }

	ngOnInit(): void { 
		this._substringDescription()
 }

//----------------substring description--------//
 private _substringDescription(){
		this.readMoreText= this._translateService.instant('read_more')
		if (this.cardData && this.cardData.description) {
			if (this.cardData.description.length >= 50) {
				this.descriptionToShow = this.cardData.description.substr(0, 50).concat(' <p class="read-more">' + this.readMoreText + '......</p>');
			} else {
				this.descriptionToShow ;
			}
		} else {
			return ; 
		}
	}
}

