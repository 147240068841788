import { CommonModule } from "@angular/common";
import { Component, inject } from "@angular/core";
import { MatMenuModule } from "@angular/material/menu";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { LanguagesService } from "@sportyano/core/services/language services/languages.service";

@Component({
	selector: "language",
	standalone: true,
	imports: [MatMenuModule, CommonModule, TranslateModule],
	templateUrl: "./language.component.html",
	styleUrl: "./language.component.scss",
})
export class LanguageComponent {
	// Inject
	private _languagesService = inject(LanguagesService);
  public translateService =inject(TranslateService)
	setCurrentLanguage(selectedLanguage: string) {
    location.reload();
		this._languagesService.setCurrentLanguage(selectedLanguage);
	}
}
