import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appPreventCopyCutPaste]',
  standalone:true
})
export class PreventCopyCutPasteDirective {

  @Input() preventCopy: boolean = true; // Default to preventing copy
  @Input() preventCut: boolean = false;
  @Input() preventPaste: boolean = false;

  constructor() {
    // console.log('PreventCopyDirective instantiated');
  }

  @HostListener('copy', ['$event'])
  onCopy(event: ClipboardEvent): void {
    if (this.preventCopy) {
      // console.log('Copy event detected and prevented');
      event.preventDefault();
    }
  }

  @HostListener('cut', ['$event'])
  onCut(event: ClipboardEvent): void {
    if (this.preventCut) {
      // console.log('Cut event detected and prevented');
      event.preventDefault();
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent): void {
    if (this.preventPaste) {
      // console.log('Paste event detected and prevented');
      event.preventDefault();
    }
  }
}
