import { Component, inject } from "@angular/core";
import { LoginComponent } from "@sportyano/auth/login/login.component";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { ActivatedRoute } from "@angular/router";
import { StateManagementService } from "@sportyano/core/services/state-managment/state-management.service";
import { SESSION_STORAGE } from "@sportyano/core/models/storage/storage.const";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { CheckLanguageDirective } from "@sportyano/shared/directives/check-language/check-language.directive";
import { DialogModule } from "primeng/dialog";
import { MatIconModule } from "@angular/material/icon";

@Component({
	selector: "app-login-popup",
	templateUrl: "./login-popup.component.html",
	styleUrl: "./login-popup.component.scss",
	standalone:true,
	imports: [
        CommonModule,
        ReactiveFormsModule,
        TranslateModule,
		DialogModule,
		CheckLanguageDirective,
		MatIconModule
    ],
})
export class LoginPopupComponent extends LoginComponent {
	private _stateManagementService = inject(StateManagementService);
	private _activeRoute = inject(ActivatedRoute);
	isMobileScreen: boolean;
	breakpointObserver = inject(BreakpointObserver);
	checkScreenSize(): void {
		this.breakpointObserver
			.observe([
				Breakpoints.Handset, // Mobile devices
				Breakpoints.Tablet, // Tablet devices
				Breakpoints.Web, // Desktop devices
			])
			.subscribe((result) => {
				if (result.matches) {
					// Mobile or Tablet view
					this.isMobileScreen = true;
				} else {
					// Desktop view
					this.isMobileScreen = false;
				}
			});
	}

	closePopup() {
		this._authService.setLoginPopupState(false);
		this.loginForm.reset();
	}

	public onRegistration(): void {
		// Cach the Route Path
		this._stateManagementService.setSessionStorage(
			SESSION_STORAGE.lastActive,
			this._router.url
		);
		// Close Dialog
		this._authService.setLoginPopupState(false);
		// Route
		this._router.navigateByUrl("/auth/register");
	}

	public onForgetPassword(): void {
		// Close Dialog
		this._authService.setLoginPopupState(false);
		// Route
		this._router.navigateByUrl("/auth/forgot-password");
	}
}
