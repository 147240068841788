import {
	Router,
	RouterModule,
} from "@angular/router";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ExtraTabItam } from "@sportyano/core/models/nav-tabs/ExtraTabItam";
import { TabItem } from "@sportyano/core/models/nav-tabs/TabItem";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";

@Component({
	selector: "nav-tabs",
	templateUrl: "./nav-tabs.component.html",
	styleUrls: ["./nav-tabs.component.scss"],
	standalone: true,
	imports: [
		CommonModule,
		TranslateModule,
		RouterModule
	],
})
export class NavTabsComponent {
	@Input({ required: true }) tabItems: TabItem[] = [];
	@Input() extraTabItems: ExtraTabItam[] = [];
	@Output() selectedTab: EventEmitter<TabItem> = new EventEmitter();

	constructor(private router: Router) {}

	checkShowExtraTab(activeTab: string): boolean {
		return this.router.url.includes(activeTab) ? true : false;
	}
}
