import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SeoService {

  constructor(
	private titleService: Title,
	private metaService: Meta,
	private router: Router,
  private translateService: TranslateService
	) {
		this.setupNavigationListener();
	}
  private setupNavigationListener(): void {
    // Reset to default meta tags on each navigation start
    this.router.events
      .pipe(filter(event => event instanceof NavigationStart))
      .subscribe(() => {
        this.setDefaultMetaData();
      });
  }
  private setDefaultMetaData(): void {
    // Use translated default values
	const defaultTitle = this.translateService.currentLang == "en" ?"Sportyano - Your Ultimate Sports Hub":"سبورتانو - مركز الرياضة النهائي";
    const defaultDescription =  this.translateService.currentLang == "en" ?"Discover Sportyano, the ultimate platform for athletes, academies, and playgrounds. Connect with sports enthusiasts, book programs, and find top-rated facilities near you.": "اكتشف سبورتانو، المنصة المثالية للرياضيين والأكاديميات وملاعب الرياضة. تواصل مع عشاق الرياضة، احجز برامج، وابحث عن أفضل المرافق القريبة منك.";
    const defaultKeywords =  this.translateService.currentLang == "en" ?"sportyano, top-rated academies, playgrounds, sports, activities":"سبورتانو، أفضل الأكاديميات، ملاعب، رياضة، أنشطة";

    this.titleService.setTitle(defaultTitle);
    this.metaService.updateTag({ name: 'description', content: defaultDescription });
    this.metaService.updateTag({ name: 'keywords', content: defaultKeywords });
  }
  setMetaData(title: string, description: string, keywords: string): void {
    // Set the page title
    this.titleService.setTitle(title);

    // Update or create the meta tags
    this.metaService.updateTag({ name: 'description', content: description });
    this.metaService.updateTag({ name: 'keywords', content: keywords });
  }
}
