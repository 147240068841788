import { Component } from '@angular/core';

@Component({
  selector: 'app-rate-cart',
  templateUrl: './rate-cart.component.html',
  styleUrl: './rate-cart.component.scss',
  standalone:true
})
export class RateCartComponent {

}
