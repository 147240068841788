import { Router } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { StaticWebsiteCredentialsService } from "../../core/services/language services/static-website-credentials.service";
import { FormsModule } from "@angular/forms";

@Component({
	selector: "app-static-website-login",
	templateUrl: "./static-website-login.component.html",
	styleUrl: "./static-website-login.component.scss",
	standalone:true,
	imports:[
		FormsModule
	]
})
export class StaticWebsiteLoginComponent implements OnInit {
	email: string;
	password: string;
	constructor(
		private StaticWebsiteCredentialsService: StaticWebsiteCredentialsService,
		private router: Router
	) {}
	ngOnInit(): void {}
	signIn() {
		if (
			this.email === this.StaticWebsiteCredentialsService.USER_EMAIL &&
			this.password === this.StaticWebsiteCredentialsService.PASSWORD
		) {
			localStorage.setItem("staticGuardAccess", "hasAccess");
			this.router.navigate(["/"]);
		} else {
			alert("WRONG CREDENTIALS!");
			return;
		}
	}
}
