import { Pipe, PipeTransform } from "@angular/core";
import { DateTimeUtils } from "@sportyano/shared/utilis/date-time-utilits";

@Pipe({
	name: "weekDay",
	standalone: true,
})
export class WeekDayPipe implements PipeTransform {
	transform(dayIndex: number): string {
		return DateTimeUtils.weekDaysMap().get(dayIndex)?.label || "";
	}
}
