import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'popUp-messaging-dialog',
  templateUrl: './messaging-dialog.component.html',
  styleUrl: './messaging-dialog.component.scss',
  standalone:true,
  imports: [
    CommonModule,
    TranslateModule
  ]
})
export class MessagingDialogComponent {
  @Input() visible: boolean = false;
  @Output() onConfirm: EventEmitter<string> = new EventEmitter<string>();
  @Output() onCancel: EventEmitter<void> = new EventEmitter<void>();

}
