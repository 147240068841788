import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";

@Component({
	selector: "app-filteration-options",
	templateUrl: "./filteration-options.component.html",
	styleUrl: "./filteration-options.component.scss",
	standalone:true,
	imports:[
		TranslateModule,
		CommonModule
	]
})
export class FilterationOptionsComponent {
	@Input() options: string[] = [];
	@Output() optionClicked = new EventEmitter<string>();

	handleOptionClick(option: string) {
		this.optionClicked.emit(option);
	}
}
