import {
	Component,
	ElementRef,
	OnDestroy,
	OnInit,
	ViewChild,
	inject,
} from "@angular/core";
import {
	AbstractControl,
	FormBuilder,
	FormGroup,
	FormsModule,
	ReactiveFormsModule,
	ValidationErrors,
	ValidatorFn,
	Validators,
} from "@angular/forms";
import { ActivatedRoute, Router, RouterLink } from "@angular/router";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { Subject, takeUntil } from "rxjs";
import { LoginCredentials } from "@sportyano/core/models/authentications/login-data.model";
import { UserRegisterationCycle } from "@sportyano/core/models/authentications/register-data.model";
import {
	SocialUserInfo,
	SocialLogin,
} from "@sportyano/core/models/authentications/social-login-data.model";
import { AuthService } from "@sportyano/core/services/authServices/auth.service";
import { ToasterMessagesService } from "@sportyano/core/services/toaster-messages/toaster-messages.service";
import intlTelInput from "intl-tel-input";
import {
	CustomValidationType,
	CustomValidators,
	VariablesValidation,
} from "@sportyano/shared/directives/custom-validation-reactiveForm";
import { SeoService } from "@sportyano/shared/services/seo/seo.service";
import { CheckLanguageDirective } from "@sportyano/shared/directives/check-language/check-language.directive";
import { MatIconModule } from "@angular/material/icon";
import { PreventCopyCutPasteDirective } from "@sportyano/shared/directives/prevent-copy-cut-paste/prevent-copy-cut-paste.directive";
import { CommonModule } from "@angular/common";
import { MatTooltipModule } from "@angular/material/tooltip";
@Component({
	selector: "app-login",
	templateUrl: "./login.component.html",
	styleUrl: "./login.component.scss",
	standalone: true,
	imports: [
		TranslateModule,
		ReactiveFormsModule,
		MatIconModule,
		PreventCopyCutPasteDirective,
		CommonModule,
		CheckLanguageDirective,
		MatTooltipModule,
		RouterLink,
		FormsModule
	],
})
export class LoginComponent implements OnInit, OnDestroy {
	// Services Injections
	private _fb = inject(FormBuilder);
	protected _router = inject(Router);
	private _toast = inject(ToasterMessagesService);
	private _activatedRoute = inject(ActivatedRoute);
	public translateService = inject(TranslateService);
	private unsubscribe$: Subject<void> = new Subject<void>();
	protected _authService = inject(AuthService);
	private _SeoService = inject(SeoService);
	// facebookProviderInstance = new FacebookAuthProvider();

	@ViewChild("phoneInput") set phoneInputRef(ref: ElementRef) {
		if (ref) {
			this.phoneInput = ref;
			this.initializePhoneNumberInput();
		}
	}
	private phoneInput!: ElementRef;
	private iti: any;
	public CustomValidationType = CustomValidationType;
	passwordVisibility: boolean = false;
	phoneNumberDialogvisible: boolean = false;
	// googleAuthPayload: UserCredential;
	phone_number: number;
	loginForm: FormGroup;
	socialMobileRegisterForm: FormGroup;
	socialOtpVerification: boolean;
	userData: UserRegisterationCycle;
	loginPopVisible: boolean;

	ngOnInit(): void {
		this.setMetaData();
		this.buildLoginForm();
		this.buildSocialMobileRegisterForm();
		this.socialOtpVerification =
			this._activatedRoute.snapshot.queryParams["socialOtpVerification"];
		this.userData = this._authService.getRegisterSessionCycle();
		if (this.socialOtpVerification) {
			this.phoneNumberDialogvisible = true;
		}

		this._authService.getLoginPopupState().subscribe({
			next: (res) => {
				this.loginPopVisible = res;
			},
		});
	}

	private setMetaData() {
		// Set SEO metadata using SeoService
		this._SeoService.setMetaData(
			this.translateService.instant("meta.login.title"),
			this.translateService.instant("meta.login.description"),
			this.translateService.instant("meta.login.keywords")
		);
	}
	private buildSocialMobileRegisterForm() {
		this.socialMobileRegisterForm = this._fb.group({
			phone_number: ["", [Validators.required, this.phoneValidator()]],
			password: ["", Validators.required, Validators.minLength(6)],
		});
	}

	// Google Sign in
	signInWithGoogle(): void {
		this._authService.loginWithGoogle();
	}

	// Facebook Sign in
	// signInWithFacebook() {
	// 	signInWithPopup(this.getAuth, this.facebookProviderInstance).then(
	// 		(result: UserCredential) => { }
	// 	);
	// }

	closeOtpDialog() {
		this.phoneNumberDialogvisible = false;
		this._router.navigateByUrl("/auth/login");
	}

	private buildLoginForm() {
		this.loginForm = this._fb.group({
			username: [
				"",
				[
					CustomValidators.required(),
					CustomValidators.egyptianMobileNumberValidation(),
				],
			],
			password: [
				"",
				[
					CustomValidators.required(),
					CustomValidators.minMaxLengthValidation(
						VariablesValidation.password_minLength,
						VariablesValidation.password_maxLength
					),
				],
			],
		});
	}
	//Getter

	get username() {
		return this.loginForm.get("username");
	}
	get password() {
		return this.loginForm.get("password");
	}

	// Main sign in
	signIn() {
		if (this.loginForm.invalid) {
			// Check for missing required fields (mobile number and password)
			if (
				!this.loginForm.get("username")?.value ||
				!this.loginForm.get("password")?.value
			) {
				this._toast.showError(
					this.translateService.instant(
						"authentication.login.form.incorrect_fields" // Show: "Please fill in a valid value to all the required fields"
					)
				);
				return;
			}

			// If the form is invalid for another reason, return a generic error message
			this._toast.showError(
				this.translateService.instant(
					"authentication.login.form.incorrect_credentials" // Show: "Mobile number or password is incorrect"
				)
			);
			return;
		}
		const loginData: LoginCredentials = {
			identifier: this.loginForm.value.username.split(" ").join(""),
			password: this.loginForm.value.password,
		};

		this._authService
			.signIn(loginData)
			.pipe(takeUntil(this.unsubscribe$))
			.subscribe({
				next: (response) => {
					const currentUrl = this._router.url as string;

					if (response["resend-otp"]) {
						this._authService.setRegisterSessionCycle(
							"autoLoginData",
							{ user: { username: loginData.identifier } }
						);
						this._router.navigate(["/auth/otp"], {
							queryParams: { resendOTP: true },
						});
						return;
					}

					// If logging from login component
					if (currentUrl === "/auth/login") {
						this._router.navigate(["/main"]);
					} else {
						// If logging from login popup
						this._authService.setLoginPopupState(false);
						window.location.reload();
					}
				},
				error: (err) => {
					const currentUrl = this._router.url as string;
					// Show error toaster and do not redirect
					this._toast.showError(
						this.translateService.instant(
							"authentication.login.form.incorrect_credentials"
						)
					);
					if (currentUrl === "/auth/login") {
						this._router.navigate(["/auth/login"]);
					} else {
						this._router.navigate([currentUrl]);
					}
				},
			});
	}

	submitPhoneNumberVerify() {
		if (this.socialMobileRegisterForm.invalid) {
			this._toast.showError(
				this.translateService.instant(
					"authentication.errors.socialError"
				)
			);
			return;
		}

		const dataToSend: SocialUserInfo = {
			name: this.userData.socialUserName,
			email: this.userData.socialUserEmail,
			phone_number: this.socialMobileRegisterForm.value.phone_number
				.split(" ")
				.join(""),
			password: this.socialMobileRegisterForm.value.password,
			provider_id: this.userData.provider_id,
			provider: this.userData.provider,
			type: this.userData.userType,
			is_active: true,
		};

		this._authService
			.socialMediaSignIn(dataToSend)
			.pipe(takeUntil(this.unsubscribe$))
			.subscribe({
				next: (res: SocialLogin) => {
					this.phoneNumberDialogvisible = false;
					this._authService.setRegisterSessionCycle(
						"socialPhoneNumberToken",
						res.token
					);
					this._authService.setRegisterSessionCycle(
						"socialPhoneNumber",
						this.phone_number
					);
					this._router.navigate(["/auth/otp"], {
						queryParams: { socialPhoneNumberVerification: true },
					});
				},
			});
	}

	// private handleGoogleSignInSuccess(result: UserCredential): void {
	// 	const providerData = result.user.providerData[0];
	// 	const email = result.user.email;

	// 	this._authService.setRegisterSessionCycle(
	// 		"provider_id",
	// 		providerData.uid
	// 	);
	// 	this._authService.setRegisterSessionCycle(
	// 		"provider",
	// 		result.providerId
	// 	);
	// 	this._authService.setRegisterSessionCycle(
	// 		"socialUserName",
	// 		providerData.displayName
	// 	);
	// 	this._authService.setRegisterSessionCycle("socialUserEmail", email);

	// 	this._authService
	// 		.socialMediaCheckEmail({ email })
	// 		.pipe(takeUntil(this.unsubscribe$))
	// 		.subscribe({
	// 			next: (res: SocialCheckMailResponse) => {
	// 				if (res.status || res.is_social) {
	// 					this._router.navigate(["/main"]);
	// 				} else {
	// 					this._router.navigate(
	// 						["/auth/register/select-your-type"],
	// 						{
	// 							queryParams: {
	// 								socialRegisteration: true,
	// 							},
	// 						}
	// 					);

	// 					this._toast.showError(res.message)
	// 				}
	// 			},
	// 		});
	// }

	private initializePhoneNumberInput() {
		const inputElement = this.phoneInput.nativeElement;
		inputElement.addEventListener("keyup", () => {
			this.socialMobileRegisterForm.controls["phone_number"].setValue(
				inputElement.value
			);
		});

		inputElement.addEventListener("countrychange", () => {
			this.socialMobileRegisterForm.controls["phone_number"].setValue("");
		});

		this.iti = intlTelInput(inputElement, {
			allowDropdown: true,
			autoInsertDialCode: true,
			autoPlaceholder: "aggressive",
			formatOnDisplay: true,
			initialCountry: "eg",
			nationalMode: true,
			separateDialCode: true,
			placeholderNumberType: "MOBILE",
			utilsScript: "node_modules/intl-tel-input/build/js/utils.js",
		});
	}

	phoneValidator(): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			const phoneNumber = control.value;
			if (!phoneNumber) {
				return null; // return if control is empty
			}

			const isLengthValid = phoneNumber.length === 11;
			const isNumeric = /^\d+$/.test(phoneNumber);

			if (!isLengthValid) {
				return { invalidLength: true };
			}

			if (!isNumeric) {
				return { invalidPhone: true };
			}

			return null;
		};
	}

	getErrorTooltip(controlName: string): string {
		const control = this.socialMobileRegisterForm.get(controlName);
		if (control?.invalid && (control?.dirty || control?.touched)) {
			if (control?.hasError("pattern")) {
				return this.translateService.instant(
					"authentication.errors.phoneNumber"
				);
			} else {
				return this.translateService.instant(
					"authentication.errors.phoneNumber"
				);
			}
		}
		return "";
	}

	showTooltip(controlName: string): boolean | null {
		const control = this.socialMobileRegisterForm.get(controlName);
		return control && control.invalid && control.dirty && control.touched;
	}

	ngOnDestroy(): void {
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}
}
