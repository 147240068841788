import { ProfileLockedService } from "@sportyano/core/services/playerProfile/profileLocked.service";
import { Router } from "@angular/router";
import {
	Component,
	EventEmitter,
	Input,
	Output,
	inject,
	OnDestroy,
	computed,
} from "@angular/core";
import { ShowinterestService } from "@sportyano/core/services/generals/showinterest.service";
import { LanguagesService } from "@sportyano/core/services/language services/languages.service";
import { ShortlistService } from "@sportyano/core/services/shortLists/shortlist.service";
import { ToasterMessagesService } from "@sportyano/core/services/toaster-messages/toaster-messages.service";
import {
	UserType,
	permission,
} from "@sportyano/core/models/permissions/permission";
import { AuthService } from "@sportyano/core/services/authServices/auth.service";
import { FavouriteListService } from "@sportyano/core/services/playerProfile/favourite-list.service";
import { UrlService } from "@sportyano/core/services/url/url.service";
import { ShareModalComponent } from "@sportyano/shared/components/share-modal/share-modal.component";
import { CommonModule } from "@angular/common";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { Observable } from "rxjs/internal/Observable";
import { shareReplay } from "rxjs/internal/operators/shareReplay";
import { map } from "rxjs/internal/operators/map";
import { MapUtils } from "@sportyano/shared/utilis/map-utilits";
import { GeneralService } from "@sportyano/core/services/generals/general.service";
import { Sport } from "@sportyano/core/models/sports/sports.model";
import { StateManagementService } from "@sportyano/core/services/state-managment/state-management.service";
import { CartComponent } from "@sportyano/shared/components/cart/cart.component";
import { StopClickPropagationDirective } from "@sportyano/shared/directives/stop-click-propagation/stop-click-propagation.directive";
import { ChipComponent } from "@sportyano/shared/components/chip/chip.component";
import { TextLimiterDirective } from "@sportyano/shared/directives/text-limiter/text-limiter.directive";

@Component({
	selector: "player-cart",
	templateUrl: "./cart-profile.component.html",
	styleUrl: "./cart-profile.component.scss",
	standalone: true,
	imports: [
		ShareModalComponent,
		CommonModule,
		TranslateModule,
		CartComponent,
		StopClickPropagationDirective,
		ChipComponent,
		TextLimiterDirective
	],
})
export class PlayerCartComponent implements OnDestroy {
	// INPUTS
	@Input() playerData: any;
	@Input() showUnlock: boolean = true;
	@Input() showShortListButton: boolean = false;
	@Input() hideFavInAccount: boolean = false;

	// Outputs
	@Output() showPlayer = new EventEmitter<string>();
	@Output() player = new EventEmitter<any>();
	// Injects
	private _showInterest = inject(ShowinterestService);
	private _unlock = inject(ProfileLockedService);
	private _toastrMessages = inject(ToasterMessagesService);
	private _favouriteService = inject(FavouriteListService);
	private _stateManagmentService = inject(StateManagementService);
	private _generalService = inject(GeneralService);
	private _translateService = inject(TranslateService);
	public sportsList$: Observable<Map<number, Sport>> = this._generalService
		.getSports()
		.pipe(
			shareReplay(1),
			map((m) => MapUtils.arrayToMap(m.records, "id"))
		);
	// Public
	currentLanguage: string = "ar";

	userType: UserType;
	permission = permission;
	userAuthenticationState: boolean = false;
	currentUrl: string;
	public currentWebsiteUrl: string;

	// Flags
	showShare: boolean = false;
	isComparsion: boolean = false;
	showDetails: boolean = false;
	playerState = computed(() =>
		this._stateManagmentService
			.getSessionStore["players"]?.find((f: { id: any; }) => f.id === this.playerData.id)
	);
	constructor(
		private languagesService: LanguagesService,
		private _authService: AuthService,
		private router: Router,
		private urlService: UrlService
	) {
		this.currentWebsiteUrl = this.urlService.getWebsiteUrl();
	}
	ngOnInit(): void {
		this.languagesService.language$.subscribe({
			next: (language: string) => {
				this.currentLanguage = language;
				// console.log('Creenet language',this.currentLanguage)
			},
		});
		this.userType = this._authService.getUserType();
		this.userAuthenticationState =
			this._authService.getAuthenticationStateSync();
		this.currentUrl = this.router.url;
		// console.log(this.playerData);
	}

	addToShortList() {
		if (this.userAuthenticationState) {
			this._favouriteService
				.toggleFavourite(this.playerData?.id, "player")
				.subscribe({
					next: (res: any) => {
						this.playerData.is_favorite =
							!this.playerData.is_favorite;
						this._toastrMessages.showSuccess(res.message);
					},
				});
		} else {
			this._authService.setLoginPopupState(true);
		}
	}

	addStarToPlayer() {
		this._showInterest.makePlayersInterest(this.playerData.id).subscribe({
			next: (res: any) => {
				this.playerData.is_interest_shown = true;
				this._toastrMessages.showSuccess(res.message);
			},
		});
		this._showInterest.makePlayersInterest(this.playerData.id).subscribe({
			next: (res: any) => {
				this.playerData.is_interest_shown = true;
				this._toastrMessages.showSuccess(res.message);
			},
		});
	}

	public likePlayer(likeState: boolean = true): void {
		this._showInterest.likePlayer(this.playerData.id).subscribe({
			next: (res: any) => {
				this.playerData.is_interest_shown = true;
				console.log("IAM");
				
				this._stateManagmentService.setPlayerState({
					id: this.playerData.id,
					is_interest_shown: true,
				});
				++this.playerData.likes;
			},
		});
	}

	unlockProfile() {
		if (this.userAuthenticationState) {
			this._unlock
				.makePlayersUnlock(this.playerData.id)
				.subscribe((data: any) => {
					this.playerData.is_unlocked = true;
					this._toastrMessages.showSuccess(data.message);
				});
		} else this._authService.setLoginPopupState(true);
	}

	// addToFavourite() {
	// 	if (this.userAuthenticationState) {
	// 		console.log('favs');

	// 		this._favouriteService
	// 			.toggleFavourite(this.playerData.id, "player")
	// 			.subscribe({
	// 				next: (res) => {
	// 					this.playerData.is_favorite=!this.playerData.is_favorite
	// 					this._toastrMessages.showSuccess(res.message);
	// 				},
	// 			});
	// 	}
	// }

	addToFavourite() {
		if (this.userAuthenticationState) {
			this._favouriteService
				.toggleFavourite(this.playerData.id, "player")
				.subscribe({
					next: (res) => {
						// console.log('player status favourate:',this.playerData.is_favorite)
						if (this.playerData.is_favorite == false) {
							this._toastrMessages.showSuccess(
								this._translateService.instant(
									"message_add_to_favourite"
								)
							);
						}
						if (
							this.playerData.is_favorite ||
							!this.playerData.hasOwnProperty("is_favorite")
						) {
							this._toastrMessages.showSuccess(
								this._translateService.instant(
									"message_romove_form_favourite"
								)
							);
						}
						this.playerData.is_favorite =
							!this.playerData.is_favorite;

						this.player.emit(this.playerData);
						this.playerDetailsToggle()
					},
				});
		} else {
			this._authService.setLoginPopupState(true);
		}
	}

	openPlayer() {		
		this.showPlayer.emit(String(this.playerData?.id));
		this.router.navigateByUrl(
			"/main/players/" + (this.playerData?.slug || 1)
		);
	}

	public playerDetailsToggle(): void {
		this.showDetails = !this.showDetails;
	}

	ngOnDestroy(): void {
		this._authService.setLoginPopupState(false);
	}
}
