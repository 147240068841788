import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { CommonModule } from "@angular/common";
import { Component, Input, OnDestroy, OnInit, WritableSignal, inject, signal } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { News } from "@sportyano/core/models/account/news";
import { BREAKPOINTS_SIZE, DEVICE_LAYOUT, DeviceLayout } from "@sportyano/core/models/layout/layout.const";
import { permission } from "@sportyano/core/models/permissions/permission";
import { Image } from "@sportyano/core/models/profiles-images-gallery/profiles.images-gallery.model";
import { SingleAcademy } from "@sportyano/core/models/single-academy-details/single-academy.model";
import { SinglePlayground } from "@sportyano/core/models/single-playground-details/single-playground.model";
import { AdvertisementService } from "@sportyano/core/services/Advertisement/advertisement.service";
import { AuthService } from "@sportyano/core/services/authServices/auth.service";
import { LanguagesService } from "@sportyano/core/services/language services/languages.service";
import { FavouriteListService } from "@sportyano/core/services/playerProfile/favourite-list.service";
import { ProfilesService } from "@sportyano/core/services/profiles/profiles.service";
import { ToasterMessagesService } from "@sportyano/core/services/toaster-messages/toaster-messages.service";
import { UrlService } from "@sportyano/core/services/url/url.service";
import { NewsComponent } from "@sportyano/main-site/players/single-player/news/news.component";
import { E3lanCardComponent } from "@sportyano/shared/components/advertisement-card/e3lan-card.component";
import {
	Advertisement,
	sectionsAds,
} from "@sportyano/shared/components/advertisement-card/models/advertisement";
import { CartSliderComponent } from "@sportyano/shared/components/cart-slider/cart-slider.component";
import { CartVideoComponent } from "@sportyano/shared/components/cart-video/cart-video.component";
import { ChipComponent } from "@sportyano/shared/components/chip/chip.component";
import { HeadSectionComponent } from "@sportyano/shared/components/head-section/head-section.component";
import { LocationCardComponent } from "@sportyano/shared/components/location-card/location-card.component";
import { MessagingDialogComponent } from "@sportyano/shared/components/messaging-dialog/messaging-dialog.component";
import { ShareModalComponent } from "@sportyano/shared/components/share-modal/share-modal.component";
import { CarouselModule } from "primeng/carousel";
import { GalleriaModule } from "primeng/galleria";
import { debounceTime, map, Observable } from "rxjs";

type ServiceType = "academy" | "playground" | "player";

@Component({
	selector: "app-sports-playground-view",
	templateUrl: "./sports-playground-view.component.html",
	styleUrl: "./sports-playground-view.component.scss",
	standalone: true,
	imports: [
		CommonModule,
		TranslateModule,
		HeadSectionComponent,
		LocationCardComponent,
		ChipComponent,
		E3lanCardComponent,
		CarouselModule,
		CartVideoComponent,
		CartSliderComponent,
		NewsComponent,
		GalleriaModule,
		ShareModalComponent,
		MessagingDialogComponent,
	],
})
export class SportsPlaygroundViewComponent implements OnInit, OnDestroy {
	private _router = inject(Router);
	private _authService = inject(AuthService);
	private _activatedRoute = inject(ActivatedRoute);
	private _profiles = inject(ProfilesService);
	private userAuthenticationState: boolean;
	@Input() currentItem: number = 1;
	@Input() currentImage: string;

	@Input() userType: string = permission.playground;

	// pageId: number;
	playgroundPageDetails: SinglePlayground;
	academyPageDetails: SingleAcademy;
	permission = permission;
	activeTab: number = 1;
	dilogIsOpen: boolean = false;

	displayLightbox: boolean = false;
	activeIndex: number = 0;
	galleryImages: Image[] = [];
	galleryNews: News[];
	totalCurrentSports: string[] = [];
	showShare: boolean = false;
	public urlToShare: null | string = null;
	public isFavourite: boolean = false;
	public currentWebsiteUrl: string;
	public isShowShimmerForE3lan:WritableSignal<boolean> = signal(true);


	private _favouriteService = inject(FavouriteListService);
	private _toaster = inject(ToasterMessagesService);
	_advertisementService = inject(AdvertisementService);
	isHandset$: Observable<boolean>;
	section1: Advertisement[];
	data: sectionsAds;
	public deviceType: WritableSignal<DeviceLayout> = signal(
		DEVICE_LAYOUT.desktop
	);
	constructor(
		private urlService: UrlService,
		private languagesService: LanguagesService,
		private breakpointObserver: BreakpointObserver
	) {
		this.isHandset$ = this.breakpointObserver
			.observe([Breakpoints.Handset])
			.pipe(map((result) => result.matches));
		this.currentWebsiteUrl = this.urlService.getWebsiteUrl();
	}
	getAdsData() {
		if (location.href.includes("academies")) {
			this._advertisementService
				.getAdvertisement("academy profile", this.deviceType())
				.subscribe({
					next: (res) => {
						this.data = res.records;
						this.section1 = this.data["section-1"]!;
						this.isShowShimmerForE3lan.set(false)
						
					},
				});
		} else if (location.href.includes("playgrounds")) {
			this._advertisementService
				.getAdvertisement("playground profile", this.deviceType())
				.subscribe({
					next: (res) => {
						this.data = res.records;
						this.section1 = this.data["section-1"]!;
						this.isShowShimmerForE3lan.set(false)

					},
				});
		}
	}
	private checkDeviceType(): void {
		this.breakpointObserver
			.observe([
				BREAKPOINTS_SIZE.mobile,
				BREAKPOINTS_SIZE.tablet,
				BREAKPOINTS_SIZE.desktop,
			])
			.pipe(debounceTime(500))
			.subscribe((state) => {
				// Set Device Type Based On BreakPoints
				switch (state.matches) {
					case state.breakpoints[BREAKPOINTS_SIZE.desktop]:
						this.deviceType.set(DEVICE_LAYOUT.desktop);
						break;
					case state.breakpoints[BREAKPOINTS_SIZE.tablet]:
						this.deviceType.set(DEVICE_LAYOUT.tablet);
						break;
					case state.breakpoints[BREAKPOINTS_SIZE.mobile]:
						this.deviceType.set(DEVICE_LAYOUT.mobile);
						break;
					default:
						this.deviceType.set(DEVICE_LAYOUT.desktop);
						break;
				}


			this.getAdsData();
		});
	}
	ngOnInit(): void {
		this.checkDeviceType();
		// this.pageId = this._activatedRoute.snapshot.params["id"];
		this.userAuthenticationState =
			this._authService.getAuthenticationStateSync();
		if (this.userType === permission.playground) {
			this.playgroundPageDetails =
				this._activatedRoute.snapshot.data["playground"];
			console.log(this.playgroundPageDetails);
			// ahmed omar edit will be here
			this.getGalleryImages(
				"playground",
				this.playgroundPageDetails.branch_owner_id
			);
			this.getGalleryVideos(
				"playgrounds",
				this.playgroundPageDetails.branch_owner_id
			);
			this._getNewsOfAcademiesAndPlaygrounds(
				"playgrounds",
				this.playgroundPageDetails.branch_owner_id
			);
			this.urlToShare = `main/playgrounds/${this.playgroundPageDetails.slug}`;
			this.isFavourite = this.playgroundPageDetails.is_favorite;
		} else {
			this.academyPageDetails =
				this._activatedRoute.snapshot.data["academy"];
			console.log(window.location.origin);

			this.getGalleryImages(
				"academy",
				this.academyPageDetails.branch_owner_id
			);
			this.getGalleryVideos(
				"academies",
				this.academyPageDetails.branch_owner_id
			);
			this._getNewsOfAcademiesAndPlaygrounds(
				"academies",
				this.academyPageDetails.branch_owner_id
			);
			this.urlToShare = `main/academies/${this.academyPageDetails.slug}`;
			this.isFavourite = this.academyPageDetails.is_favorite;
		}
	}

	closeDialog(string: string) {
		this.dilogIsOpen = false;
	}

	getGalleryImages(
		typeOfUser: "player" | "playground" | "academy",
		pageDetailsId: number
	) {
		this._profiles
			.getPublicGalleryImages(typeOfUser, pageDetailsId)
			.subscribe({
				next: (res) => {
					if (res.images) {
						this.galleryImages = res.images;
					}
				},
			});
	}
	galleryVideos: any[];
	getGalleryVideos(
		typeOfUser: "players" | "playgrounds" | "academies",
		pageDetailsId: number
	) {
		this._profiles
			.getPublicGalleryVideos(typeOfUser, pageDetailsId)
			.subscribe({
				next: (res) => {
					if (res.videos) {
						this.galleryVideos = res.videos;
					}
				},
			});
	}

	private _getNewsOfAcademiesAndPlaygrounds(
		uerType: "players" | "playgrounds" | "academies",
		id: number
	) {
		this._profiles.getPublicNews(uerType, id).subscribe({
			next: (res) => {
				this.galleryNews = res.news;
			},
		});
	}

	slidesStore: any[] = [
		{
			src: "assets/images/homepage/alena-aenami-003-1.jpg",
		},
		{
			src: "assets/images/homepage/alena-aenami-7p-m-1k.jpg",
		},
		{
			src: "assets/images/homepage/alena-aenami-7p-m-1k.jpg",
		},
	];
	playersCarouselResponsiveOptions = [
		{
			breakpoint: "1199px",
			numVisible: 1,
			numScroll: 1,
		},
		{
			breakpoint: "991px",
			numVisible: 1,
			numScroll: 1,
		},
		{
			breakpoint: "767px",
			numVisible: 1,
			numScroll: 1,
		},
	];

	responsiveOptions = [
		{
			breakpoint: "1500px",
			numVisible: 5,
		},
		{
			breakpoint: "1024px",
			numVisible: 3,
		},
		{
			breakpoint: "768px",
			numVisible: 2,
		},
		{
			breakpoint: "560px",
			numVisible: 1,
		},
	];

	navigateToSchedule(id: number) {
		// if (this.userAuthenticationState) {
		this._router.navigateByUrl(
			this.academyPageDetails
				? "/main/academies/program-schedule/" + id
				: "/main/playgrounds/playground-schedule/" + id
		);
		// } else {
		// 	this._authService.setLoginPopupState(true);
		// }
	}

	ngOnDestroy(): void {
		this._authService.setLoginPopupState(false);
	}

	imageClick(index: number) {
		this.activeIndex = index;
		this.displayLightbox = true;
	}

	nextImage(e: number) {
		if (e < this.galleryImages.length - 1) {
			this.currentImage = this.galleryImages[e + 1].url;
			this.currentItem = e + 1;
		} else {
			this.currentImage =
				this.galleryImages[this.galleryImages?.length - 1].url;
			this.currentItem = this.galleryImages?.length - 1;
		}
	}

	prevImage(e: number) {
		if (e > 0) {
			this.currentImage = this.galleryImages[e - 1].url;
			this.currentItem = e - 1;
		} else {
			this.currentImage = this.galleryImages[0].url;
			this.currentItem = 0;
		}
	}

	public toggleFavourite() {
		if (this.userAuthenticationState) {
			let type: ServiceType;
			let id: number;

			if (this.userType === permission.playground) {
				type = "playground";
				id = this.playgroundPageDetails.id;
			} else {
				type = "academy";
				id = this.academyPageDetails.id;
			}

			this.setFavourite(id, type);
		} else {
			this._authService.setLoginPopupState(true);
		}
	}

	private setFavourite(id: number, type: ServiceType) {
		this._favouriteService.toggleFavourite(id, type).subscribe({
			next: (res) => {
				this._toaster.showSuccess(res.message);
				this.isFavourite = !this.isFavourite;
			},
		});
	}
}
