import { CommonModule } from "@angular/common";
import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import {
	UserType,
	permission,
} from "@sportyano/core/models/permissions/permission";
import { AuthService } from "@sportyano/core/services/authServices/auth.service";
import { CartComponent } from "@sportyano/shared/components/cart/cart.component";
import { PaginationComponent } from "@sportyano/shared/components/paggination/paggination.component";
import { TextLimiterDirective } from "@sportyano/shared/directives/text-limiter/text-limiter.directive";
import { GalleriaModule } from "primeng/galleria";

@Component({
	selector: "app-news",
	templateUrl: "./news.component.html",
	styleUrl: "./news.component.scss",
	standalone: true,
	imports: [
		CommonModule,
		PaginationComponent,
		CartComponent,
		TextLimiterDirective,
		TranslateModule,
		GalleriaModule
	],
})
export class NewsComponent implements OnInit {
	// ViewChild
	@ViewChild(CartComponent) cartComponent!: CartComponent;
	userType: UserType;
	authenticated: boolean;
	pageIndex: number = 1;
	pageSize: number = 10;
	displayLightbox: boolean = false;
	activeIndex: number = 0;
	responsiveOptions = [
		{
			breakpoint: "1500px",
			numVisible: 5,
		},
		{
			breakpoint: "1024px",
			numVisible: 3,
		},
		{
			breakpoint: "768px",
			numVisible: 2,
		},
		{
			breakpoint: "560px",
			numVisible: 1,
		},
	];
	@Input() Data: any;

	constructor(
		private _authService: AuthService
	) {}

	ngOnInit(): void {		
		this.userType = this._authService.getUserType();
		if (this.userType) {
			this.authenticated = true;
		} else {
			this.authenticated = false;
		}
		// this.singlePlayerService.singlePlayer$.subscribe((res) => {
		// 	console.log(res,'News Component');
		// 	this.playerData = res;
		// });
	}

	isPlayground(): boolean {
		return this.userType == permission.playground;
	}
	
	imageClick(index: number) {
		console.log(this.Data, index)
		this.activeIndex = index;
		this.displayLightbox = true;
	}

	changePage(e: any) {
		this.pageIndex = e.pageIndex;
		this.pageSize = e.pageSize;
	}
}
