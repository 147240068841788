<header
	dir="ltr"
	class="lg:rounded-3xl transition-all w-full lg:w-4/5 z-[999] left-1/2 transform -translate-x-1/2 sticky top-0"
>
	<div class="bg-primaryBlack dark:primaryBlack">
		<div
			class="max-w-screen-xl flex-wrap flex items-center justify-between mx-auto p-2"
			[ngStyle]="langStyles"
		>

			<button
				(click)="menuToggle()"
				type="button"
				class="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
			>
				<span class="sr-only">{{
					"header.menu_button.label" | translate
				}}</span>
				<svg
					class="w-5 h-5"
					aria-hidden="true"
					xmlns="http://www.w3.org/2000/svg"
					fill="none"
					viewBox="0 0 17 14"
				>
					<path
						stroke="currentColor"
						stroke-linecap="round"
						stroke-linejoin="round"
						stroke-width="2"
						d="M1 1h15M1 7h15M1 13h15"
					/>
				</svg>
			</button>
			
			<a
				class="flex items-center space-x-3 rtl:space-x-reverse cursor-pointer"
			>
				<span class="flex flex-col justify-center items-center">
					<img
						style="height: 45px"
						[src]="
							translationcurr == 'en'
								? 'assets/images/logo/sportyano-logo-en.png'
								: 'assets/images/logo/Web logo.png'
						"
						[routerLink]="'/'"
						class="h-6 lg:h-10"
						alt="{{ 'header.logo_alt' | translate }}"
					/>
					<small class="beta-version w-fit">{{
						"betaVersion" | translate
					}}</small>
				</span>
			</a>
			<div
				class="flex md:order-2 me-4 lg:me-0 space-x-3 lg:space-x-3 rtl:space-x-reverse"
				[ngStyle]="langStyles"
			>
				<div #accountMenuContainer>
					<li
						*ngIf="isAuthenticated$ | async"
						[appClickOutside]="accountMenuContainer"
						(clickOutsideEmitter)="accountMenuOpened = false"
						class="before:contents __user-account lg:ms-8 xl:ms-4"
					>
					<div class="flex items-center space-x-2">
						<span class="hidden md:inline text-fontColor font-normal p-2 text-sm lg:text-base">
							{{ "header.account_items.my_account" | translate }}
						</span>
						<button
						id="dropdownNotificationButton"
						class="border border-solid border-fontColor rounded-full p-1 lg:p-2"
						type="button"
						(click)="openAccountMenu()"
						[ngClass]="{
							'bg-lightBlue': accountMenuOpened,
							'border-lightBlue': accountMenuOpened
						}"
						>
							<svg
							xmlns="http://www.w3.org/2000/svg"
							width="32"
							height="32"
							viewBox="0 0 21.922 25.053"
							>
								<path
								id="Icon_awesome-user"
								data-name="Icon awesome-user"
								d="M10.961,12.527A6.263,6.263,0,1,0,4.7,6.263,6.263,6.263,0,0,0,10.961,12.527Zm4.384,1.566h-.817a8.518,8.518,0,0,1-7.134,0H6.576A6.578,6.578,0,0,0,0,20.669V22.7a2.349,2.349,0,0,0,2.349,2.349H19.573A2.349,2.349,0,0,0,21.922,22.7V20.669A6.578,6.578,0,0,0,15.345,14.092Z"
								fill="#d9d9d9"
								/>
							</svg>
						</button>
					</div>
				<div
				[ngStyle]="langStyles"
				class="__dropdown-menu z-20 bg-lightBlue transition-all rounded-lg absolute left-[10%] top-[100%] res-dropdown responsive-dropdown"
				*ngIf="accountMenuOpened"
							[ngClass]="{
								'left-0 right-auto': currentLanguage === 'ar',
								'right-0 left-auto': currentLanguage === 'en'
							}"
						>
							@for (item of accountManagementMenu; track $index) {
							<div
								[routerLink]="
									item.disabled || !item.route
										? null
										: [item.route]
								"
								(click)="handleAccountListClick(item)"
								class="__notification-item account-management transition-all w-[305px] py-2 first-of-type:mt-2 last-of-type:pt-2 last-of-type:mb-2 max-w-full"
								[ngClass]="
									item.disabled
										? 'bg-gray-900 text-gray-500 '
										: 'hover:bg-textYellow text-fontColor cursor-pointer hover:text-darkBlue'
								"
							>
								<a
									class="flex justify-between items-center px-4 capitalize text-sm font-normal"
								>
									{{ item.label | translate }}
									<small
										*ngIf="item.disabled"
										class="text-gray-500"
										>{{
											"errorMessages.activeSoon"
												| translate
										}}
									</small>
								</a>
							</div>
							}
						</div>
					</li>
				</div>

				<div
					class="items-center space-ber gap-10 justify-between space-x-4 rtl:space-x-reverse language-selector hidden md:flex"
				>
					<span
						class="login-register"
						*ngIf="(isAuthenticated$ | async) === false"
					>
						<a
							routerLink="/auth/login"
							class="cursor-pointer py-2 text-base rtl:text-xl px-3 text-fontColor transition-all md:p-0 md:hover:text-amber-300"
							>{{
								"header.menu_items.login_register" | translate
							}}</a
						>
					</span>
					<div class="languages">
						<language></language>
					</div>
				</div>
			</div>

			<div
				[ngClass]="isMenuOpened ? '' : 'hidden'"
				class="w-full lg:flex lg:w-auto xl:order-1"
				id="navbar-sticky"
			>
				<div class="container">
					<ul
						(click)="menuToggle()"
						[ngStyle]="langStyles"
						class="flex flex-col lg:flex-row items-center p-4 xl:p-0 mt-4 font-medium text-center xl:text-start space-y-6 xl:space-y-0 rounded-lg bg-primaryBlack xl:space-x-8 xl:mt-0 xl:border-0 xl:bg-primaryBlack dark:primaryBlack xl:dark:primaryBlack dark:primaryBlack relative"
					>
						<li
							[ngClass]="
								currentLanguage === 'ar'
									? 'effect-hover-players-item-ar'
									: 'effect-hover-players-item-en'
							"
						>
							<a
								routerLinkActive="text-textYellow"
								routerLink="/main/players"
								[queryParams]="{ profile: paramsPage.players }"
								[ngStyle]="langAnchorStyles"
								class="block cursor-pointer py-2 text-base rtl:text-xl px-3 text-fontColor transition-all uppercase md:hover:text-amber-300"
								>{{
									"header.menu_items.players" | translate
								}}</a
							>
						</li>
						<li class="nav-items-hover-effect">
							<a
								routerLinkActive="text-textYellow"
								routerLink="/main/academies"
								[queryParams]="{
									profile: paramsPage.academies
								}"
								class="block cursor-pointer py-2 text-base rtl:text-xl px-3 text-fontColor transition-all uppercase md:hover:text-amber-300"
								>{{
									"header.menu_items.sport_academies"
										| translate
								}}</a
							>
						</li>
						<!-- Playgrounds -->
						<li class="nav-items-hover-effect">
							<a
								routerLinkActive="text-textYellow"
								routerLink="/main/playgrounds"
								[queryParams]="{
									profile: paramsPage.playgrounds
								}"
								class="block cursor-pointer py-2 text-base rtl:text-xl px-3 text-fontColor transition-all uppercase md:hover:text-amber-300"
								>{{
									"header.menu_items.playgrounds" | translate
								}}</a
							>
						</li>
						<!-- Media gallery -->
						<li class="nav-items-hover-effect">
							<a
								routerLinkActive="text-textYellow"
								routerLink="/main/media"
								[queryParams]="{ profile: paramsPage.videos }"
								class="block cursor-pointer py-2 text-base rtl:text-xl px-3 text-fontColor transition-all uppercase md:hover:text-amber-300"
								>{{
									"header.menu_items.media_gallery"
										| translate
								}}</a
							>
						</li>

						<li StopClickPropagation class=" md:hidden lg:hidden">
							<language StopClickPropagation></language>
						</li>
						<li class=" md:hidden lg:hidden ">
							<span
								class="login-register"
								*ngIf="(isAuthenticated$ | async) === false"
							>
								<a
									routerLink="/auth/login"
									class="cursor-pointer py-2 text-base rtl:text-xl px-3 text-fontColor transition-all md:hover:text-amber-300"
									>{{
										"header.menu_items.login_register"
											| translate
									}}</a
								>
							</span>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</header>
