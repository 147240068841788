import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";

@Component({
	selector: "tab-subscription",
	templateUrl: "./subscription.component.html",
	styleUrl: "./subscription.component.scss",
	standalone: true,
	imports: [
		TranslateModule,
		CommonModule,
		FormsModule
	],
})
export class SubscriptionComponent {
	// Replace Cancel Button With Continue In Registeration
	@Input() hiddenInAccount: boolean = false;

	// TO SHARE THE SELECTED PLAN BETWEEN OTHER COMPONENTS
	@Output() emitSelectedPlan: EventEmitter<Plan> = new EventEmitter<Plan>();

	@Input({ required: true }) selectedSubscription: number = 1;
	@Input({ required: true }) subscription: Subscription[] = [
		new Subscription(1, "Monthly"),
		new Subscription(2, "Annually"),
	];

	@Input({ required: true }) selectedPlan: number = 1;

	@Input({ required: true }) subscriptionPlans: Plan[] = [
		new Plan(
			1,
			"Free plan",
			0.0,
			[
				"5 No. of branches",
				"25 Profile unlocks",
				"Lorem ipsum dolor sit amet",
				"Lorem ipsum dolor sit amet",
				"Lorem ipsum dolor sit amet",
			],
			true
		),
		new Plan(
			2,
			"Premium",
			4.99,
			[
				"5 No. of branches",
				"25 Profile unlocks",
				"Lorem ipsum dolor sit amet",
				"Lorem ipsum dolor sit amet",
				"Lorem ipsum dolor sit amet",
			],
			false
		),
		new Plan(
			3,
			"Professional",
			16.95,
			[
				"5 No. of branches",
				"25 Profile unlocks",
				"Lorem ipsum dolor sit amet",
				"Lorem ipsum dolor sit amet",
				"Lorem ipsum dolor sit amet",
			],
			false
		),
		new Plan(
			4,
			"Professional",
			16.95,
			[
				"5 No. of branches",
				"25 Profile unlocks",
				"Lorem ipsum dolor sit amet",
				"Lorem ipsum dolor sit amet",
				"Lorem ipsum dolor sit amet",
			],
			false
		),
	];

	subscribe(plan: Plan, subscriptionId: number): void {
		this.subscriptionPlans.forEach((plan) => {
			plan.Subscribed = plan.Id == subscriptionId;
		});
		this.emitSelectedPlan.emit(plan);
	}
}

class Plan {
	Id: number;
	Name: string;
	Price: number;
	Items: string[];
	Subscribed: boolean;

	constructor(
		Id: number,
		Name: string,
		Price: number,
		Items: string[],
		Subscribed: boolean = false
	) {
		this.Id = Id;
		this.Name = Name;
		this.Price = Price;
		this.Items = Items;
		this.Subscribed = Subscribed;
	}
}

class Subscription {
	Id: number;
	Name: string;
	constructor(Id: number, Name: string) {
		this.Id = Id;
		this.Name = Name;
	}
}
