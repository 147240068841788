import { CommonModule } from '@angular/common';
import { Component, input, InputSignal } from '@angular/core';
import { ICONS, IconsType } from '@sportyano/shared/components/icon/models/icon';

@Component({
  selector: 'icon',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './icon.component.html',
  styleUrl: './icon.component.scss'
})
export class IconComponent {
  
public name :InputSignal<IconsType> = input.required()
public iconClass :InputSignal<string> = input('w-[3rem] h-[3rem]')

// Public
public ICONS =ICONS
}
