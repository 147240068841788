export const BREAKPOINTS_SIZE = {
	mobile: "(max-width: 767px)",
	tablet: "(min-width: 767px) and (max-width: 1024px)",
	desktop: "(min-width: 1025px)",
} as const;

export const DEVICE_LAYOUT = {
	mobile: "mobile",
	tablet: "tablet",
	desktop: "desktop",
} as const;



export type DeviceLayout =
    (typeof DEVICE_LAYOUT)[keyof typeof DEVICE_LAYOUT];
