
import { ParamMap } from '@angular/router';

export const ParamsUtils = {
    search: (paramMap: ParamMap, key: string = 'search'): string | null => {
      const q = paramMap.get(key);
      return (q && q.trim()) || null;
    },
    number: (paramMap: ParamMap, key: string = 'number'): number | null => {
      const q = paramMap.get(key);
      return q ? +q : null;
    },
    bool: (paramMap: ParamMap, key: string): boolean | null => {
      const q = paramMap.get(key);
      return q === '1' || q === 'true'
        ? true
        : q === '2' || q === 'false'
        ? false
        : null;
    },
    queryParam: (paramsMap: ParamMap, key: string): string | null => {
      const q = paramsMap.get(key);
      return (q && q.trim()) || null;
    },
  };