// import { CommonModule } from '@angular/common';
// import { Component, inject, input, signal } from '@angular/core';
// import { ActivatedRoute } from '@angular/router';
// import { TranslateModule, TranslateService } from '@ngx-translate/core';
// import { IProgramDetails } from '@sportyano/core/models/programs/program-details';
// import { AcademyService } from '@sportyano/core/services/academy/academies/academy.service';
// import { AuthService } from '@sportyano/core/services/authServices/auth.service';
// import { ChipComponent } from '@sportyano/shared/components/chip/chip.component';
// import { IconComponent } from '@sportyano/shared/components/icon/icon.component';
// import { ICONS } from '@sportyano/shared/components/icon/models/icon';
// import { TimeFormatPipe } from '@sportyano/shared/pipes/time-format/time-format.pipe';
// import { WeekDayPipe } from '@sportyano/shared/pipes/week-day/week-day.pipe';
// import { ParamsUtils } from '@sportyano/shared/utilis/query-param.utilis';
// import { filter, Observable, switchMap, tap } from 'rxjs';
import { CommonModule } from '@angular/common';
import { Component, inject, input, signal } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { IProgramDetails } from '@sportyano/core/models/programs/program-details';
import { AcademyService } from '@sportyano/core/services/academy/academies/academy.service';
import { AuthService } from '@sportyano/core/services/authServices/auth.service';
import { ChipComponent } from '@sportyano/shared/components/chip/chip.component';
import { IconComponent } from '@sportyano/shared/components/icon/icon.component';
import { ICONS } from '@sportyano/shared/components/icon/models/icon';
import { TimeFormatPipe } from '@sportyano/shared/pipes/time-format/time-format.pipe';
import { WeekDayPipe } from '@sportyano/shared/pipes/week-day/week-day.pipe';
import { ParamsUtils } from '@sportyano/shared/utilis/query-param.utilis';
import { filter, Observable, switchMap, tap } from 'rxjs';
@Component({
  selector: 'app-program-details-quick',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    IconComponent,
    ChipComponent,
    TimeFormatPipe,
    WeekDayPipe,
  ],
  templateUrl: './program-details-quick.component.html',
  styleUrl: './program-details-quick.component.scss'
})
export class programDetailsQuickComponent {

  // Input
  public details = input<IProgramDetails | null>(null);
  // Inject
  public translateService = inject(TranslateService);
  public _router = inject(ActivatedRoute);
  public _authService = inject(AuthService);
  private _academyService = inject(AcademyService);

  // Observables
  public isUserLogin$: Observable<boolean> =
    this._authService.getAuthenticationState();

  // Signals
  public programDetails = signal<IProgramDetails | null>(null);
  public queryData = signal<{
    isPending: boolean;
    programId: string | null;
  } | null>(null);
  // Public
  public ICONS = ICONS;

  ngOnInit(): void {
    this._listenToQueryParam();
    console.log(this.queryData);

  }

  private _listenToQueryParam(): void {
    this._router.queryParamMap
      .pipe(
        filter((f) => ParamsUtils.bool(f, "pending") || !!f.get("id")),
        tap((t) => {
          this.queryData.set({
            isPending: !!ParamsUtils.bool(t, "pending"),
            programId: ParamsUtils.search(t, "id")!,
          });
        }),
        switchMap((s) => {
          const programId = ParamsUtils.search(s, "id")!
          return ParamsUtils.bool(s, "pending")
            ? this._academyService.getPendingProgramDetails(programId)
            : this._academyService.getProgramDetails(programId);
        })
      )
      .subscribe({
        next: (res) => {
          if ("program" in res) {
            this.programDetails.set(res.program);
          } else {
            this.programDetails.set(res.data);
          }
        },
      });
  }
}
