<!-- <h2 >Install Angular</h2> -->

<app-header></app-header>
<div class="popup-stepper h-full overflow-hidden">
	<mat-dialog-content class="mat-typography">
		<mat-stepper [linear]="true" #stepper>
			<!-- Program Details -->
			<mat-step>
				<ng-template matStepLabel>{{
					"quickRegister.headers.details" | translate
				}}</ng-template>
				<app-program-details-quick
					[details]="programData"
				></app-program-details-quick>

				<div
					class="absolute bottom-0 right-0 left-0 bg-gray-900 p-2 rounded-2xl flex justify-between"
				>
					<button
						matStepperNext
						class="group px-5 py-2 rounded-lg my-3 bg-blue-500 rtl:mr-auto ltr:ml-auto"
					>
						{{ "next" | translate }}
					</button>
				</div>
			</mat-step>

			<!-- phone  / OTP -->
			<mat-step>
				<ng-template matStepLabel>{{
					"quickRegister.headers.phone" | translate
				}}</ng-template>

				<div class="h-full flex flex-col mt-2">
					<div class="flex items-center justify-center w-full my-6">
						<hr
							class="w-[10%] h-px bg-gray-200 border-0 dark:bg-gray-700"
						/>
						<span class="mx-3 font-medium text-textYellow">
							{{
								"quickRegister.labels.verifyNumber" | translate
							}}
						</span>

						<hr
							class="w-[10%] h-px bg-gray-200 border-0 dark:bg-gray-700"
						/>
					</div>
					@if(!showOTPStep()){

					<div @fadeUp>
						<p class="text-xs font-thin text-center">
							{{
								"quickRegister.labels.verifyNumberDescription"
									| translate
							}}
						</p>

						<form
							class="flex-1 flex flex-col"
							[formGroup]="programForm"
						>
							<div class="__username">
								<label
									for="username"
									class="capitalize text-textYellow text-xs block pb-3"
								>
									{{
										"authentication.login.form.username_email"
											| translate
									}}
								</label>
								<input
									required
									maxlength="11"
									[appCheckLanguage]="'numbers'"
									[dir]="
										translateService.currentLang === 'en'
											? 'ltr'
											: 'rtl'
									"
									id="username"
									class="w-full py-3 px-4 border-none bg-inputFieldBackground rounded-md placeholder-fontColor text-fontColor text-xs shadow-md"
									placeholder="{{
										'authentication.login.form.username_placeholder'
											| translate
									}}"
									required
									formControlName="username"
								/>
								<div
									*ngIf="
										programForm.get('username')?.invalid &&
										(programForm.get('username')?.dirty ||
											programForm.get('username')
												?.touched)
									"
								>
									<small
										class="text-primaryRed text-xs mt-2"
										*ngIf="
											programForm
												.get('username')
												?.hasError(
													CustomValidationType.required
												)
										"
									>
										{{
											"authentication.errors.required"
												| translate
										}}
									</small>
									<small
										class="text-primaryRed text-xs mt-2"
										*ngIf="
											programForm
												.get('username')
												?.hasError(
													CustomValidationType.mobileNumberValidation
												) &&
											!programForm
												.get('username')
												?.hasError(
													CustomValidationType.required
												)
										"
									>
										{{
											"authentication.errors.egyptian_mobile_number"
												| translate
										}}
									</small>
								</div>
							</div>
							<div class="flex-1"></div>
						</form>
					</div>
					}@else {
					<div @fadeUp>
						<p class="text-xs font-thin text-center">
							{{
								"authentication.otp.a_one_time_code_has_been_sent"
									| translate
							}}
						</p>

						<section class="__otp-screen text-center text-dark">
							<div class="__otp-handler mt-10 mb-5" dir="ltr">
								<ng-otp-input
									[formCtrl]="otpFormControl"
									#ngOtpInput
									[config]="{ length: 4 }"
								></ng-otp-input>
							</div>

							<span
								class="text-fontColor font-normal text-sm capitalize block"
							>
								@if( resendOtpAttempts()<=maximumOtpAttempts){
								<span>
									{{
										"authentication.otp.dont_recive_code"
											| translate
									}}</span
								>

								<button
									[disabled]="countdownSeconds()"
									(click)="resendOtp()"
									class="font-bold py-2 px-4 rounded"
									[ngClass]="
										countdownSeconds()
											? ' text-fontColor  opacity-50 cursor-not-allowed'
											: ' important-underline text-textGreen cursor-pointer'
									"
								>
									<span
										class="font-normal text-sm capitalize block"
									>
										{{
											"authentication.otp.resend_code"
												| translate
										}}
										@if(countdownSeconds()){
										{{ countdownSeconds() }}
										}
									</span>
								</button>

								}@else{
								<span class="text-primaryRed">
									{{
										"authentication.otp.maximum_attempts"
											| translate
									}}</span
								>
								}
							</span>
						</section>
					</div>
					}
				</div>

				<div
					class="absolute bottom-0 right-0 left-0 bg-gray-900 p-2 rounded-2xl flex justify-between"
				>
					<button
						matStepperPrevious
						class="group px-5 py-2 rounded-lg my-3 border border-lightBlue text-lightBlue"
					>
						{{ "back" | translate }}
					</button>
					@if (!showOTPStep()) {

					<button
						(click)="sendOtpToPhone()"
						[disabled]="programForm.get('phone_number')?.invalid"
						[ngClass]="
							programForm.get('phone_number')?.invalid
								? 'bg-gray-600  text-gray-700'
								: 'bg-blue-500'
						"
						class="px-5 py-2 my-3 rounded-lg"
					>
						{{ "quickRegister.buttons.continue" | translate }}
					</button>

					}@else {
					<button
						type="submit"
						(click)="submitOtp()"
						[disabled]="otpFormControl?.value?.length < 4"
						[ngClass]="
							otpFormControl?.value?.length < 4
								? 'bg-gray-600  text-gray-700'
								: 'bg-blue-500'
						"
						class="px-5 py-2 my-3 rounded-lg"
					>
						{{ "quickRegister.buttons.continue" | translate }}
					</button>

					}
				</div>
			</mat-step>

			<!-- Basic FORM -->
			<mat-step>
				<ng-template matStepLabel>{{
					"quickRegister.headers.basic" | translate
				}}</ng-template>

				<div class="mt-2">
					<div class="flex items-center justify-center w-full my-6">
						<hr
							class="w-[10%] h-px bg-gray-200 border-0 dark:bg-gray-700"
						/>
						<span class="mx-3 font-medium text-textYellow">
							{{
								"quickRegister.labels.registrationTitle"
									| translate
							}}
						</span>

						<hr
							class="w-[10%] h-px bg-gray-200 border-0 dark:bg-gray-700"
						/>
					</div>

					<p
						class="text-xs font-thin text-center"
						[innerHTML]="
							'quickRegister.labels.registrationDescription'
								| translate
						"
					></p>

					<form [formGroup]="playerDataInfo">
						<div class="grid grid-cols-12 gap-4">
					
							<!-- first_name_ar -->
							<div class="col-span-6">
								<p class="text-sm text-textYellow mb-2">
									{{
										"formControls.first_name_ar" | translate
									}}
									<span class="required">*</span>
								</p>
								<input
									type="text"
									formControlName="first_name_ar"
									placeholder="{{
										'placeholder.name' | translate
									}}"
									class="border border-fontColor text-sm rounded-md text-fontColor h-10 w-full bg-semiDarkBlue focus:outline-none focus:shadow-none focus:border-fontColor"
									[appCheckLanguage]="'arabicWithoutNumbers'"
								/>
								<ul
									class="text-red-500"
									*ngIf="
										playerDataInfo.get('first_name_ar')?.invalid &&
										(playerDataInfo.get('first_name_ar')?.dirty || playerDataInfo.get('first_name_ar')?.touched)
									"
								>
									<li
										*ngIf="
											playerDataInfo.get('first_name_ar')?.hasError(
												CustomValidationType.required
											)
										"
										class="text-red-500 text-xs"
									>
										{{
											"player_info_errors.first_name_req"
												| translate
										}}
									</li>
								</ul>
							</div>

							<!-- last_name_ar -->
							<div class="col-span-6">
								<p class="text-sm text-textYellow mb-2">
									{{
										"formControls.last_name_ar" | translate
									}}
									<span class="required">*</span>
								</p>
								<input
									type="text"
									formControlName="last_name_ar"
									placeholder="{{
										'placeholder.name' | translate
									}}"
									class="border border-fontColor text-sm rounded-md text-fontColor h-10 w-full bg-semiDarkBlue focus:outline-none focus:shadow-none focus:border-fontColor"
									[appCheckLanguage]="'arabicWithoutNumbers'"
								/>
								<ul
									class="text-red-500"
									*ngIf="
										playerDataInfo.get('last_name_ar')?.invalid &&
										(playerDataInfo.get('last_name_ar')?.dirty || playerDataInfo.get('last_name_ar')?.touched)
									"
								>
									<li
										*ngIf="
											playerDataInfo.get('last_name_ar')?.hasError(
												CustomValidationType.required
											)
										"
										class="text-red-500 text-xs"
									>
										{{
											"player_info_errors.first_name_req"
												| translate
										}}
									</li>
								</ul>
							</div>

							<!-- first_name_en -->
							<div class="col-span-6">
								<p class="text-sm text-textYellow mb-2">
									{{
										"formControls.first_name_en" | translate
									}}
									<span class="required">*</span>
								</p>
								<input
									type="text"
									formControlName="first_name_en"
									placeholder="{{
										'placeholder.name' | translate
									}}"
									class="border border-fontColor text-sm rounded-md text-fontColor h-10 w-full bg-semiDarkBlue focus:outline-none focus:shadow-none focus:border-fontColor"
									[appCheckLanguage]="'englishWithoutNumbers'"
								/>
								<ul
									class="text-red-500"
									*ngIf="
										playerDataInfo.get('first_name_en')?.invalid &&
										(playerDataInfo.get('first_name_en')?.dirty || playerDataInfo.get('first_name_en')?.touched)
									"
								>
									<li
										*ngIf="
											playerDataInfo.get('first_name_en')?.hasError(
												CustomValidationType.required
											)
										"
										class="text-red-500 text-xs"
									>
										{{
											"player_info_errors.first_name_req"
												| translate
										}}
									</li>
								</ul>
							</div>

							<!-- last_name_en -->
							<div class="col-span-6">
								<p class="text-sm text-textYellow mb-2">
									{{
										"formControls.last_name_en" | translate
									}}
									<span class="required">*</span>
								</p>
								<input
									type="text"
									formControlName="last_name_en"
									placeholder="{{
										'placeholder.name' | translate
									}}"
									class="border border-fontColor text-sm rounded-md text-fontColor h-10 w-full bg-semiDarkBlue focus:outline-none focus:shadow-none focus:border-fontColor"
									[appCheckLanguage]="'englishWithoutNumbers'"
								/>
								<ul
									class="text-red-500"
									*ngIf="
										playerDataInfo.get('last_name_en')?.invalid &&
										(playerDataInfo.get('last_name_en')?.dirty || playerDataInfo.get('last_name_en')?.touched)
									"
								>
									<li
										*ngIf="
											playerDataInfo.get('last_name_en')?.hasError(
												CustomValidationType.required
											)
										"
										class="text-red-500 text-xs"
									>
										{{
											"player_info_errors.first_name_req"
												| translate
										}}
									</li>
								</ul>
							</div>
							<!--viewer_email User Type viewer -->
							<div class="col-span-12 lg:col-span-6">
								<p class="text-sm text-textYellow mb-2">
									{{
										"account.account_management.viewer_Info.Viewer_email"
											| translate
									}}
									<span class="required">*</span>
								</p>
								<input
									type="text"
									formControlName="email"
									placeholder="{{
										'placeholder.email' | translate
									}}"
									class="border border-fontColor text-sm rounded-md text-fontColor h-10 w-full bg-semiDarkBlue focus:outline-none focus:shadow-none focus:border-fontColor"
								/>
								<ul
									class="text-red-500"
									*ngIf="
										email?.invalid &&
										(email?.dirty || email?.touched)
									"
								>
									<li
										*ngIf="
											email?.hasError(
												CustomValidationType.required
											)
										"
										class="text-red-500 text-xs"
									>
										{{
											"player_info_errors.email_required"
												| translate
										}}
									</li>
									<li
										*ngIf="
											email?.hasError(
												CustomValidationType.patternValidator
											) &&
											!email?.hasError(
												CustomValidationType.required
											)
										"
										class="text-red-500 text-xs"
									>
										{{
											"player_info_errors.invalid_email"
												| translate
										}}
									</li>
								</ul>
							</div>
						</div>
					</form>
				</div>
				<div
					class="absolute bottom-0 right-0 left-0 bg-gray-900 p-2 rounded-2xl flex justify-between"
				>
					<button
						matStepperPrevious
						class="group px-5 py-2 rounded-lg my-3 border border-lightBlue text-lightBlue"
					>
						{{ "back" | translate }}
					</button>
					<button
						(click)="onSubmit()"
						[disabled]="playerDataInfo.invalid"
						class="group px-5 py-2 rounded-lg my-3"
						[ngClass]="
							playerDataInfo.invalid
								? 'bg-gray-600  text-gray-700'
								: 'bg-blue-500'
						"
					>
						{{ "quickRegister.buttons.register" | translate }}
					</button>
				</div>
			</mat-step>

			<!-- Done -->
			<mat-step>
				<ng-template matStepLabel>{{
					"account.academy_programs_schedule.done" | translate
				}}</ng-template>
				<div class="container">
					<div class="flex justify-center">
						<ng-lottie class="w-1/3" [options]="congratsLottie" />
					</div>
					<div class="text-2xl font-bold text-green-500 text-center">
						{{ "congratulations" | translate }}
					</div>

					<p class="text-center">
						{{
							"quickRegister.labels.registrationSuccessfully"
								| translate
						}}
						<span class="highlight">{{ programData.name_en }}</span
						>.
					</p>

					<div class="grid grid-cols-12 gap-5">
						<div
							class="col-span-6 flex gap-2 items-center justify-center bg-darkBlue p-2 px-4 rounded-2xl"
						>
							<icon
								[name]="ICONS.cost"
								iconClass="w-[1.5rem] h-[1.5rem]"
							></icon>
							<div class="flex flex-col">
								<small class="text-textYellow"
									>{{
										"account.academy_programs_schedule.price"
											| translate
									}}
								</small>
								<p class="text-fontColor text-base break-words">
									<strong class="text-white pr-1">{{
										programData.total_amount
											| number : "2.0-1"
									}}</strong>
									<small>{{
										"SPORT_ACADEMIES.Currency_eg"
											| translate
									}}</small>
								</p>
							</div>
						</div>

						<!-- start_date -->
						<div
							class="col-span-6 flex gap-2 items-center justify-center bg-darkBlue p-2 px-4 rounded-2xl"
						>
							<icon
								[name]="ICONS.timer"
								iconClass="w-[1.5rem] h-[1.5rem]"
							></icon>
							<div class="flex flex-col">
								<small class="text-textYellow"
									>{{
										"account.academy_programs_schedule.start_date"
											| translate
									}}
								</small>
								<p class="text-fontColor text-base break-words">
									<strong class="text-white pr-1">{{
										programData.start_date
									}}</strong>
								</p>
							</div>
						</div>
					</div>

					<div class="my-2 text-center">
						{{
							"quickRegister.labels.redirectToProgramDetails"
								| translate
						}}
						<span class="text-lightBlue">
							{{
								countdownRedirect() +
									" " +
									("dateTime.sec" | translate)
							}}</span
						>
					</div>
				</div>
				<div
					class="absolute bottom-0 right-0 left-0 bg-gray-900 p-2 rounded-2xl flex justify-between"
				>
					<button
						matStepperPrevious
						class="group px-5 py-2 rounded-lg my-3 border border-lightBlue text-lightBlue"
					>
						{{ "back" | translate }}
					</button>
					<button
						(click)="closeDialog()"
						class="group px-5 py-2 rounded-lg my-3 bg-blue-500"
					>
						{{ "quickRegister.buttons.redirect" | translate }}
					</button>
				</div>
			</mat-step>
		</mat-stepper>
	</mat-dialog-content>
</div>
