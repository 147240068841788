import { CommonModule } from "@angular/common";
import {
	Component,
	CUSTOM_ELEMENTS_SCHEMA,
	Input,
	signal,
	SimpleChanges,
} from "@angular/core";
import { Advertisement } from "@sportyano/shared/components/advertisement-card/models/advertisement";
import { CarouselModule } from "primeng/carousel";
import { ButtonModule } from "primeng/button";
import { TagModule } from "primeng/tag";
@Component({
	selector: "e3lan-card",
	templateUrl: "./e3lan-card.component.html",
	styleUrl: "./e3lan-card.component.scss",
	standalone: true,
	imports: [CommonModule, ButtonModule, TagModule, CarouselModule],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class E3lanCardComponent {
	@Input() data: Advertisement[];
	@Input() deviceType: string;
	@Input() isLoading=signal(false)
	@Input() image: string | string[];
	@Input() link: string;
	@Input() sectionNumber: number;
	@Input() advisementLayout: string ;
	@Input() customHeight: string = "100%";
	@Input() customWidth: string = "80%";
	// device-type = "mobile"

	ngOnChanges(changes: SimpleChanges): void {
		// this.data = changes["data"].currentValue;
		if (changes['data'] && changes['data'].currentValue) {
			this.setAutoplay();
		}
	}

	
	responsiveOptions: any[];
	ads: any[];
	autoplayInterval:number=0;
	ngOnInit() {
		this.responsiveOptions = [
			{
				breakpoint: "1199px",
				numVisible: 1,
				numScroll: 1,
			},
			{
				breakpoint: "991px",
				numVisible: 1,
				numScroll: 1,
			},
			{
				breakpoint: "767px",
				numVisible: 1,
				numScroll: 1,
			},
		];
		if (this.data && this.data.length > 0) {
			setTimeout(() => this.isLoading.set(false), 2000); // Stop shimmer after data loads
		  }
		  this.setAutoplay();
	}
	setAutoplay() {
		this.autoplayInterval = this.data.length > 1 ? 3000 : 0;
	}

	public navigateTO(url: string | null | undefined): void {
		if (url) {
			// console.log("Navigating to URL:", url); // Debug line
			window.open(url, "_blank");
		}
	}
}
