<div class="mb-0 h-full">
	<p-carousel
		*ngIf="!isLoading()"
		[value]="data"
		[numVisible]="1"
		[showNavigators]="false"
		[numScroll]="1"
		[circular]="true"
		[autoplay]="true"
		[autoplayInterval]="autoplayInterval"
		[responsiveOptions]="responsiveOptions"
		class="h-full"
		[style]="{ display: 'block', height: '100%', width: '100%' }"
	>
		<ng-template pTemplate="item" let-advertisement>
			<div
				*ngIf="advertisement.position === 'vertical'"
				(click)="navigateTO(advertisement.link)"
				class="w-full"
			>
				<ng-container
					*ngIf="
						deviceType === 'desktop' && advertisement.desktop_image
					"
				>
					<img
						[src]="advertisement.desktop_image"
						alt="Vertical(Desktop)"
						class="w-full"
					/>
				</ng-container>
				<!-- Tablet Image -->
				<ng-container
					*ngIf="
						deviceType === 'tablet' && advertisement.tablet_image
					"
				>
					<img
						[src]="advertisement.tablet_image"
						alt="Vertical(Tablet)"
						width="610"
						height="650"
					/>
				</ng-container>
				<ng-container
					*ngIf="
						deviceType === 'mobile' && advertisement.mobile_image
					"
				>
					<img
						[src]="advertisement.mobile_image"
						alt="Vertical(MOBILE)"
						width="288"
						height="380"
					/>
				</ng-container>
			</div>

			<div
				*ngIf="advertisement.position === 'horizontal'"
				class="e3lan-section w-full h-full horizontal"
				(click)="navigateTO(advertisement.link)"
			>
				<ng-container
					*ngIf="
						deviceType === 'desktop' && advertisement.desktop_image
					"
				>
					<img
						[src]="advertisement.desktop_image"
						alt="Horizontal(Desktop)"
						class="w-[80%]"
					/>
				</ng-container>
				<!-- Tablet Image -->
				<ng-container
					*ngIf="
						deviceType === 'tablet' && advertisement.tablet_image
					"
				>
					<img
						[src]="advertisement.tablet_image"
						alt="Horizontal(Tablet)"
						class="w-[80%]"
					/>
				</ng-container>
				<ng-container
					*ngIf="
						deviceType === 'mobile' && advertisement.mobile_image
					"
				>
					<img
						[src]="advertisement.mobile_image"
						alt="Horizontal(MOBILE)"
						class="w-[80%]"
					/>
				</ng-container>
			</div>
		</ng-template>
	</p-carousel>

	<!--Loading for hirzoinal-->
	<div
		*ngIf="isLoading() && advisementLayout !== 'vertical'"
		class="p-4 w-full flex gap-2 mx-auto"
	>
		<div class="animate-pulse w-full flex flex-col items-center space-y-4">
			<!-- Image -->
			<div class="bg-gray-300 h-40 w-3/4 rounded-lg shimmer"></div>
		</div>
	</div>
	<!--Loading for vertical -->
	<div
		*ngIf="isLoading() && advisementLayout === 'vertical'"
		class="flex items-center p-4 max-w-sm w-full mx-auto h-full"
	>
		<div class="animate-pulse w-full h-full">
			<!-- Shimmer Image -->
			<div class="bg-gray-300 w-full min-h-full rounded-lg shimmer"></div>
		</div>
	</div>
</div>
