import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FileUploadResponse } from '@sportyano/core/models/file-upload/file-upload.model';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root'
})
export class CommonService {

	constructor(private http: HttpClient) { }

	CommonPostRequests(model: any, url: string): Observable<any> {
		return this.http.post<any>(`${url}`, model);
	}
	CommonPostRequestsWithHeaders(model: any, url: string,headers:any): Observable<any> {
		return this.http.post<any>(`${url}`, model,headers);
	}
	CommonPutRequests(model: any, url: string): Observable<any> {
		return this.http.put<any>(`${url}`, model);
	}
	CommonPatchRequests(model: any, url: string): Observable<any> {
		return this.http.patch<any>(`${url}`, model);
	}
	CommonGetRequests(url: string): Observable<any> {
		return this.http.get<any>(`${url}`);
	}

	CommonGetWithParamRequests(url: string, params: any): Observable<any> {
		return this.http.get<any>(`${url}`, {
			params: params,
		});
	}

	CommonDeleteRequest(url: string): Observable<any> {
		return this.http.delete<any>(`${url}`);
	}

	CommonGetRequestsWithQuery(url: string, Model: any): Observable<any> {
		if (Model) {
			let queryString = Object.keys(Model).map((key: string) =>
				Model[key] != null && Model[key] != '' && Model[key] != undefined ? key + '=' + Model[key] : null
			).filter(x => x != null).join('&');
			url += queryString == '' ? '' : '?' + queryString;
		}
		return this.http.get<any>(`${url}`);
	}


	CommonPostRequestsWithQuery(url: string, Model: any, body: any): Observable<any> {
		if (Model) {
			let queryString = Object.keys(Model).map((key: string) =>
				Model[key] != null && Model[key] != '' && Model[key] != undefined || Model[key] === false ? key + '=' + Model[key] : null
			).filter(x => x != null).join('&');
			url += queryString == '' ? '' : '?' + queryString;
		}
		return this.http.post<any>(`${url}`, body);
	}

	//used to download File
	CommonDownloadFileRequests(searchModel: any, url: string): Observable<any> {
		if (searchModel) {
			let queryString = Object.keys(searchModel).map((key: string) =>
				searchModel[key] != null && searchModel[key] != '' && searchModel[key] != 0 && searchModel[key] != undefined || searchModel[key] === false ? key + '=' + searchModel[key] : null
			).filter(x => x != null).join('&');

			url += queryString == '' ? '' : '?' + queryString;
		}
		return this.http.get<any>(`${url}`, { observe: 'response', responseType: 'blob' as 'json' });
	}


	uploadFile(model: FormData): Observable<FileUploadResponse> {
		return this.http.post<FileUploadResponse>(`${environment.baseUrl}upload`, model, {
			headers: new HttpHeaders({
				'encType': "multipart/form-data",
			}),
		})
	}

	uploadVideo(model: FormData): Observable<FileUploadResponse> {
		return this.http.post<FileUploadResponse>(`${environment.baseUrl}`, model, {
			headers: new HttpHeaders({
				'encType': "multipart/form-data",
			}),
		})
	}


}
