import { Component } from '@angular/core';

@Component({
  selector: 'app-dynamic-dialog',
  templateUrl: './dynamic-dialog.component.html',
  styleUrl: './dynamic-dialog.component.scss',
  standalone:true
})
export class DynamicDialogComponent {

}
