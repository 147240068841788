import { Inject, Injectable, PLATFORM_ID, inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {
	BehaviorSubject,
	from,
	Observable,
	of,
	switchMap,
	take,
	tap,
} from "rxjs";
import { isPlatformBrowser } from "@angular/common";
import { NavigationExtras, Router } from "@angular/router";
import {
	getCookie,
	setCookie,
	removeCookie,
} from "@sportyano/core/common/utils/cookies-controller";
import { ChangePasswordCredentials } from "@sportyano/core/models/authentications/change-password.model";
import {
	AutoLoginData,
	LoginCredentials,
	LoginResponse,
	UserData,
} from "@sportyano/core/models/authentications/login-data.model";
import {
	PhoneVerification,
	Otp,
	OtpResponse,
	IResendOtp,
} from "@sportyano/core/models/authentications/otp-model";
import {
	UserRegisterationCycle,
	RegisterData,
	RegisterResponse,
} from "@sportyano/core/models/authentications/register-data.model";
import { ResetPasswordCredentials } from "@sportyano/core/models/authentications/reset-password.model";
import {
	SocialUserInfo,
	SocialLogin,
	SocialCheckMailResponse,
} from "@sportyano/core/models/authentications/social-login-data.model";
import {
	UserType,
	permission,
} from "@sportyano/core/models/permissions/permission";
import { environment } from "src/environments/environment";
import { AUTH_CONSTANT } from "@sportyano/core/models/authentications/auth-constant";
import {
	Auth,
	GoogleAuthProvider,
	signInWithPopup,
	UserCredential,
} from "@angular/fire/auth";
import { IVerifyEmailRequest } from "@sportyano/core/models/authentications/auhtentication.interface";

@Injectable({
	providedIn: "root",
})
export class AuthService {
	private _fireBaseAuth = inject(Auth);
	private forgotPasswordCompleted = false;
	private readonly sessionKey: string = "userRegisterCycle";
	private readonly userDataStorageKey: string = "userData";
	private readonly userTypeStorageKey: string = "userType";
	readonly adminAccessStorageKey: string = "adminAccess";
	private readonly apiUrl: string = environment.baseUrl;
	private readonly tokenKey: string = AUTH_CONSTANT.token;
	private isAuthenticated: BehaviorSubject<boolean> =
		new BehaviorSubject<boolean>(false);
	private loginPopupState: BehaviorSubject<boolean> =
		new BehaviorSubject<boolean>(false);
	private quickLoginPopupState: BehaviorSubject<boolean> =
		new BehaviorSubject<boolean>(false);
	private readonly _httpClient = inject(HttpClient);
	private readonly _router = inject(Router);
	private userRegisterCycleData: UserRegisterationCycle =
		{} as UserRegisterationCycle;

	private _isTemporaryRegistrationEnabled$: BehaviorSubject<boolean> =
		new BehaviorSubject<boolean>(false);
	private _googleProviderInstance = new GoogleAuthProvider();

	public set setUserData(userData: UserData | null) {
		// Store user data
		localStorage.setItem(
			this.userDataStorageKey,
			JSON.stringify(userData || {})
		);
	}

	constructor(@Inject(PLATFORM_ID) private platform: object) {
		if (isPlatformBrowser(platform)) {
			// If user authenticated initially, set auth state to true
			if (getCookie(this.tokenKey)) {
				this.setAuthenticationState(true);
			}
			// Registeration cycle data
			const storedRegisterCycleData = sessionStorage.getItem(
				this.sessionKey
			);
			if (storedRegisterCycleData) {
				this.userRegisterCycleData = JSON.parse(
					storedRegisterCycleData
				);
			}
		}
	}

	public set setToken(accessToken: string) {
		setCookie(this.tokenKey, accessToken || "");
	}

	setRegisterSessionCycle(key: string, value: any): void {
		this.userRegisterCycleData[key] = value;
		this.saveToSession();
	}

	getRegisterSessionCycle(): UserRegisterationCycle {
		return this.userRegisterCycleData;
	}

	private saveToSession(): void {
		if (isPlatformBrowser(this.platform)) {
			sessionStorage.setItem(
				this.sessionKey,
				JSON.stringify(this.userRegisterCycleData)
			);
		}
	}

	// Tracking user authentication state
	getAuthenticationState(): Observable<boolean> {
		return this.isAuthenticated.asObservable();
	}

	// Tracking user authentication state without subscribtion
	getAuthenticationStateSync(): boolean {
		return this.isAuthenticated.value;
	}

	// Set login popup open/closed state
	setLoginPopupState(value: boolean) {
		this.loginPopupState.next(value);
	}

	// Set Quick login popup open/closed state
	setQuickLoginPopupState(value: boolean) {
		this.quickLoginPopupState.next(value);
	}

	// Get login popup open/closed state
	getLoginPopupState(): Observable<boolean> {
		return this.loginPopupState.asObservable();
	}

	// Get Quick login popup open/closed state
	getQuickLoginPopupState(): Observable<boolean> {
		return this.quickLoginPopupState.asObservable();
	}

	setAuthenticationState(authValue: boolean): void {
		this.isAuthenticated.next(authValue);
	}

	getTokenCookieKey(): string {
		return this.tokenKey;
	}

	signIn(credentials: LoginCredentials): Observable<LoginResponse> {
		return this._httpClient
			.post<LoginResponse>(this.apiUrl + "auth/login", credentials)
			.pipe(
				switchMap((response) => {
					// Store access token
					setCookie(this.tokenKey, response.token || "");

					// Store user type
					localStorage.setItem(
						this.userTypeStorageKey,
						response.userType || ""
					);

					// Store user data
					this.setUserData = response?.user ?? null;

					// Set user authentication state to true
					this.setAuthenticationState(true);

					// Return a new observable with the response
					return of(response);
				})
			);
	}

	signUp(
		userInformation: FormData,
		userType: string
	): Observable<RegisterResponse> {
		return this._httpClient.post<RegisterResponse>(
			this.apiUrl + `auth/${userType}/register`,
			userInformation
		);
	}

	signOut(): Observable<{ message: string }> {
		return this._httpClient
			.post<{ message: string }>(this.apiUrl + "auth/logout", {})
			.pipe(
				switchMap((res) => {
					if (res) {
						this.removeAuthenticationData();
						this._navigateToLoginPage();
						this.setAuthenticationState(false);
					}
					return of(res);
				})
			);
	}

	removeAuthenticationData() {
		if (isPlatformBrowser(this.platform)) {
			removeCookie(this.tokenKey);
			localStorage.removeItem(this.userTypeStorageKey);
			localStorage.removeItem(this.userDataStorageKey);
			localStorage.removeItem("adminAccess");
		}
	}
	private _navigateToLoginPage(): void {
		this._router.navigateByUrl("/auth/login");
	}

	setAuthenticationData(response: AutoLoginData) {
		if (isPlatformBrowser(this.platform)) {
			// Store user type
			localStorage.setItem(
				this.userTypeStorageKey,
				response.userType || ""
			);

			// Store user data
			localStorage.setItem(
				this.userDataStorageKey,
				JSON.stringify(response.user || {})
			);

			// Set user authentication state to true
			this.setAuthenticationState(true);
		}
	}

	socialMediaSignIn(
		userInformation: SocialUserInfo
	): Observable<SocialLogin> {
		return this._httpClient.post<SocialLogin>(
			this.apiUrl + "auth/social/login",
			userInformation
		);
	}

	socialMediaCheckEmail(userEmail: {
		email: string | null;
	}): Observable<SocialCheckMailResponse> {
		return this._httpClient
			.post<SocialCheckMailResponse>(
				this.apiUrl + "auth/social/check",
				userEmail
			)
			.pipe(
				switchMap((response: SocialCheckMailResponse) => {
					if (response.status || response.is_social) {
						// Store access token
						setCookie(this.tokenKey, response.token || "");

						// Store user type
						localStorage.setItem(
							this.userTypeStorageKey,
							response?.user?.user_type || ""
						);

						// Store user data
						localStorage.setItem(
							this.userDataStorageKey,
							JSON.stringify(response.user || {})
						);

						// Set user authentication state to true
						this.setAuthenticationState(true);
					}

					// Return a new observable with the response
					return of(response);
				})
			);
	}

	forgotPassword(phoneNumber: PhoneVerification) {
		return this._httpClient.post(
			this.apiUrl + "players/reset-password/request",
			phoneNumber
		);
	}

	resetPassword(resetCredentials: ResetPasswordCredentials) {
		return this._httpClient.post(
			this.apiUrl + "players/reset-password",
			resetCredentials
		);
	}

	changePasswordCredentials(changeCredentials: ChangePasswordCredentials) {
		return this._httpClient.post(
			this.apiUrl + "auth/change-password",
			changeCredentials
		);
	}

	otpVerification(userData: Otp): Observable<OtpResponse> {
		return this._httpClient.post<OtpResponse>(
			this.apiUrl + "phone-numbers/verify",
			userData
		);
	}

	changePasswordOtpVerification(
		phoneNumber: PhoneVerification | Otp
	): Observable<OtpResponse> {
		return this._httpClient.post<OtpResponse>(
			this.apiUrl + "auth/phone-number/otp",
			phoneNumber
		);
	}
	changePassword(phoneNum: PhoneVerification): Observable<any> {
		return this._httpClient.post(
			`${this.apiUrl}reset-password/request`,
			phoneNum
		);
	}
	changePasswordOTP(otpWithPass: any): Observable<any> {
		return this._httpClient.post(
			`${this.apiUrl}reset-password/`,
			otpWithPass
		);
	}

	public resendOTP(phone: string): Observable<IResendOtp> {
		return this._httpClient.post<IResendOtp>(
			this.apiUrl + "auth/phone-number/resend-otp",
			{
				phone,
			}
		);
	}

	// Use it only inside isPlatformBrowser
	getUserData(): UserData {
		if (isPlatformBrowser(this.platform)) {
			const userData = localStorage.getItem(this.userDataStorageKey);
			return userData ? JSON.parse(userData) : null;
		}

		return {} as UserData;
	}

	// Use it only inside isPlatformBrowser
	getUserType(): UserType {
		if (isPlatformBrowser(this.platform)) {
			const userType = localStorage.getItem(this.userTypeStorageKey);
			return userType as UserType;
		}

		return {} as UserType;
	}

	autoAdminSignIn(accessData: LoginResponse) {
		removeCookie(this.tokenKey);
		localStorage.removeItem(this.userTypeStorageKey);
		localStorage.removeItem(this.userDataStorageKey);
		localStorage.removeItem(this.tokenKey);
		localStorage.removeItem(this.adminAccessStorageKey);
		localStorage.removeItem("user");

		// Store access token
		setCookie(this.tokenKey, accessData.token || "");

		// Store user type
		localStorage.setItem(
			this.userTypeStorageKey,
			accessData.userType || ""
		);

		// Store user data
		localStorage.setItem(
			this.userDataStorageKey,
			JSON.stringify(accessData.user || {})
		);

		localStorage.setItem(this.adminAccessStorageKey, accessData.type);

		// Set user authentication state to true
		this.setAuthenticationState(true);

		if (accessData.userType === permission.player) {
			this._router.navigate(
				["/main/account/profile-management/player-info"],
				{ queryParams: { activeEditMode: true } }
			);
		}

		if (
			accessData.userType === permission.academy ||
			accessData.userType === permission.playground
		) {
			this._router.navigate([
				"/main/account/branches-management/edit-branche/" +
					accessData.profile_id,
			]);
		}
	}

	get isAdmin(): string {
		if (isPlatformBrowser(this.platform)) {
			return localStorage.getItem(this.adminAccessStorageKey) || "";
		}

		return "";
	}

	public set setTemporaryRegistrationState(state: boolean) {
		this._isTemporaryRegistrationEnabled$.next(state);
	}

	public get getTemporaryRegistrationState$(): Observable<boolean> {
		return this._isTemporaryRegistrationEnabled$.asObservable();
	}

	public loginWithGoogle() {
		const login = signInWithPopup(
			this._fireBaseAuth,
			this._googleProviderInstance
		)
			.then((result: UserCredential) => {
				console.log("result", result);
				this.handleGoogleSignInSuccess(result);
			})
			.catch((e) => {
				console.log("error", e);
			});
		return from(login);
	}

	private handleGoogleSignInSuccess(result: UserCredential): void {
		const providerData = result.user.providerData[0];
		const email = result.user.email;

		this.setRegisterSessionCycle("provider_id", providerData.uid);
		this.setRegisterSessionCycle("provider", result.providerId);
		this.setRegisterSessionCycle(
			"socialUserName",
			providerData.displayName
		);
		this.setRegisterSessionCycle("socialUserEmail", email);

		this.socialMediaCheckEmail({ email })
			.pipe(take(1))
			.subscribe({
				next: (res: SocialCheckMailResponse) => {
					if (res.status || res.is_social) {
						this._router.navigate(["/main"]);
					} else {
						this._router.navigate(
							["/auth/register/select-your-type"],
							{
								queryParams: {
									socialRegisteration: true,
								},
							}
						);

						// this._toast.showError(res.message);
					}
				},
			});
	}

	setForgotPasswordCompleted(status: boolean): void {
		this.forgotPasswordCompleted = status;
	}

	isForgotPasswordCompleted(): boolean {
		return this.forgotPasswordCompleted;
	}

	public emailVerification(email: string): Observable<any> {
		return this._httpClient.post(`${environment.baseUrl}email-addresses`, {
			email: email,
		});
	}

	public resendEmailVerification(email: string): Observable<any> {
		return this._httpClient.post(`${environment.baseUrl}email-verification/resend`, {
			email: email,
		});
	}

	
	public verifyUserEmail(payload: IVerifyEmailRequest) {
		return this._httpClient.post(
			`${environment.baseUrl}email-addresses/verify`,
			payload
		);
	}


}
